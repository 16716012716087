import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomQuestionnaire, NewQuestion } from '../interfaces';
import {
  CREATE_CUSTOM_QUESTIONNAIRE,
  GET_COMPANY_SUGGESTED_SKILLS,
  GET_COMPANY_SUGGESTED_TOPICS,
  RETRIEVE_A_CUSTOM_QUESTIONNAIRE,
} from './thunk';

interface QuestionnaireWizardState {
  loading: boolean;
  generalStep: CustomQuestionnaire;
  questionsStep: NewQuestion[];
  companyTopics: { id: number; text: string }[];
  companySkills: { id: number; text: string }[];
}

export const emptyQuestion: NewQuestion = {
  text: '',
  score: 0,
  order: 1,
  topics: [],
  answers: [
    { text: '', score: 0, order: 1 },
    { text: '', score: 0, order: 2 },
  ],
};

const initialState: QuestionnaireWizardState = {
  loading: false,
  generalStep: {
    title: '',
    author: '',
    description: '',
    skills: [],
    topics: [],
    duration: null,
  },
  questionsStep: [{ ...emptyQuestion }],
  companyTopics: [],
  companySkills: [],
};

const newCustomQuestionnaireSlice = createSlice({
  name: 'newCustomQuestionnaire',
  initialState,
  reducers: {
    updateGeneralStep: (state, action: PayloadAction<CustomQuestionnaire>) => {
      state.generalStep = { ...state.generalStep, ...action.payload };
    },
    updateQuestionsStep: (state, action: PayloadAction<NewQuestion[]>) => {
      state.questionsStep = [...action.payload];
    },
    resetWizard: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(GET_COMPANY_SUGGESTED_SKILLS.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        GET_COMPANY_SUGGESTED_SKILLS.fulfilled,
        (state, action: PayloadAction<{ id: number; text: string }[]>) => ({
          ...state,
          loading: false,
          companySkills: action.payload,
        })
      )
      .addCase(GET_COMPANY_SUGGESTED_SKILLS.rejected, (state) => ({
        ...state,
        loading: false,
      }))
      .addCase(GET_COMPANY_SUGGESTED_TOPICS.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        GET_COMPANY_SUGGESTED_TOPICS.fulfilled,
        (state, action: PayloadAction<{ id: number; text: string }[]>) => ({
          ...state,
          loading: false,
          companyTopics: action.payload,
        })
      )
      .addCase(GET_COMPANY_SUGGESTED_TOPICS.rejected, (state) => ({
        ...state,
        loading: false,
      }))
      .addCase(RETRIEVE_A_CUSTOM_QUESTIONNAIRE.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        RETRIEVE_A_CUSTOM_QUESTIONNAIRE.fulfilled,
        (state, action: PayloadAction<CustomQuestionnaire>) => {
          const { title, author, description, skills, topics, duration } = action.payload;

          const questionsStep = topics.reduce((acc: NewQuestion[], topic) => {
            topic.questions.forEach((tq) => {
              if (!acc.find((question) => question.id === tq.id)) {
                acc = [
                  ...acc,
                  {
                    ...tq,
                    topics: topics
                      .filter((t) => t.questions.map((q) => q.id).includes(tq.id))
                      .map((t) => t.text),
                  },
                ];
              }
            });
            return acc;
          }, []);

          return {
            ...state,
            loading: false,
            generalStep: {
              title,
              author: author ?? '',
              description: description ?? '',
              skills,
              topics,
              duration: duration ? duration / 60 : null, // Convert seconds to minutes
            },
            questionsStep,
          };
        }
      )
      .addCase(RETRIEVE_A_CUSTOM_QUESTIONNAIRE.rejected, (state) => ({
        ...state,
        loading: false,
      }))
      .addCase(CREATE_CUSTOM_QUESTIONNAIRE.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(CREATE_CUSTOM_QUESTIONNAIRE.fulfilled, () => initialState)
      .addCase(CREATE_CUSTOM_QUESTIONNAIRE.rejected, (state) => ({
        ...state,
        loading: false,
      })),
});

export const { updateGeneralStep, updateQuestionsStep, resetWizard } =
  newCustomQuestionnaireSlice.actions;

export default newCustomQuestionnaireSlice.reducer;
