import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import {
  removeErrorMessage,
  removeSuccessMessage,
  setErrorMessage,
  setSuccessMessage,
} from './actions';

export interface PopupState {
  error: { message: string; [key: string]: unknown } | null;
  success: { message: string; [key: string]: unknown } | null;
}

const PopupInitialState: PopupState = {
  error: null,
  success: null,
};

const reducerPopupMessage = createReducer(PopupInitialState, (builder) =>
  builder
    .addCase(
      setErrorMessage,
      (state, action: PayloadAction<{ message: string; [key: string]: unknown }>) => ({
        ...state,
        error: action.payload,
      })
    )
    .addCase(removeErrorMessage, (state, _) => ({
      ...state,
      error: null,
    }))
    .addCase(
      setSuccessMessage,
      (state, action: PayloadAction<{ message: string; [key: string]: unknown }>) => ({
        ...state,
        success: action.payload,
      })
    )
    .addCase(removeSuccessMessage, (state, _) => ({
      ...state,
      success: null,
    }))
);

export default reducerPopupMessage;
