import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { BACKEND_ENDPOINTS } from '../../constants/endpoints';
import { ActionsEnum } from '../../enums/actions.enum';
import {
  RetrieveJobPostData,
  UpdateJobPostStatusResponse,
} from '../../models/ThunksInterfaces/retrieveJobPosts';
import { http } from '../../utils/http';
import { setErrorMessage, setSuccessMessage } from '../popup/actions';

export const RETRIEVE_JOB_POSTS = createAsyncThunk(
  ActionsEnum.RETRIEVE_JOB_POSTS,
  async ({
    companyId,
    status,
    name,
    orderBy,
    limit,
    skip,
  }: {
    companyId: number;
    status: string;
    name: string;
    orderBy: string;
    limit: number;
    skip: number;
  }): Promise<RetrieveJobPostData> => {
    let data: RetrieveJobPostData;
    try {
      const options: AxiosRequestConfig = {
        method: 'GET',
        params: {
          companyId: `${companyId}`,
          status,
          orderBy,
          limit,
          skip,
        },
      };
      if (name) {
        options.params.name = name;
      }
      const response = await http(BACKEND_ENDPOINTS.JOB_POSTS, options);
      data = response.data.data;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);

export const UPDATE_JOB_POST_STATUS = createAsyncThunk(
  ActionsEnum.UPDATE_JOB_POST_STATUS,
  async (
    { jobPostId, status }: { jobPostId: number; status: 'active' | 'archived' },
    thunkAPI
  ): Promise<UpdateJobPostStatusResponse> => {
    let data: UpdateJobPostStatusResponse;
    try {
      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}`, {
        method: 'PATCH',
        data: {
          status,
        },
      });
      data = {
        jobPostId,
        status,
        job: {
          ...response.data.data,
        },
      };
    } catch (e: any) {
      thunkAPI.dispatch(
        setErrorMessage({ message: 'recruitment.changeStatus.reject', action: status })
      );
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(
      setSuccessMessage({ message: 'recruitment.changeStatus.confirm', action: status })
    );
    return data;
  }
);
