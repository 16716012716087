import styled from 'styled-components';

interface Props {
  visible: boolean;
  onClick: () => void;
}

const Overlay = ({ visible = false, onClick }: Props) => {
  return (
    <Container
      visible={visible}
      onClick={onClick}
    />
  );
};

export default Overlay;

const Container = styled.div<{ visible: boolean }>`
  position: fixed;
  z-index: ${(props) => (props.visible ? 100 : -1)};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.visible ? 'rgba(0, 0, 0, .65)' : 'transparent')};
  transition: background-color 0.2s ease-in-out;
`;
