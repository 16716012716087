import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { ACCESS_TOKEN, HASHED_APP_VERSION, REFRESH_TOKEN } from '../../constants/auth';
import { BACKEND_ENDPOINTS } from '../../constants/endpoints';
import { AccountTypeEnum } from '../../enums/accountType.enum';
import { ActionsEnum } from '../../enums/actions.enum';
import { CompanyDbModel } from '../../models/company.model';
import { ResetPasswordPayload } from '../../models/Forms/changePasswordPayload.model';
import { RecoveryPayloadModel } from '../../models/Forms/recoveryPayload.model';
import { VerifyPayloadModel } from '../../models/Forms/verifyPayload.model';
import { CustomJwtUserPayload, JwtUserPayload } from '../../models/userDb.model';
import { http, setAuthorizationToken } from '../../utils/http';
import { flushTokens } from '../../utils/localStorage';
import { setErrorMessage, setSuccessMessage } from '../popup/actions';

interface LoginPayload {
  email: string;
  password: string;
  token: string;
}

export interface CompanyDetailsPayload {
  id: number;
  avatar?: File;
  name?: string;
  address?: string;
  fiscal_code?: string;
  vat_number?: string;
  rea_number?: string;
  email?: string;
  pec?: string;
}

export const LOGIN = createAsyncThunk(
  ActionsEnum.LOGIN,
  async (
    { email, password, token }: LoginPayload,
    thunkAPI
  ): Promise<CustomJwtUserPayload | { otp_required: boolean; email: string }> => {
    let backendVersion: string = '';
    let data;
    try {
      const response = await http(BACKEND_ENDPOINTS.LOGIN, {
        method: 'POST',
        data: {
          email,
          password,
          token,
        },
      });
      backendVersion = response.headers['x-sv'];
      data = response.data.data;
    } catch (e) {
      if (e instanceof AxiosError) {
        thunkAPI.dispatch(setErrorMessage({ message: 'wrongPassword' }));
        throw Error(e.response?.data.error.message);
      }
    }
    // The otp is required by default, but for internal people it's not. Therefore, we need to check if the backend sends us the tokens.
    if (data.access_token && data.refresh_token && data.otp_required === false) {
      const version = `${backendVersion}_${HASHED_APP_VERSION}`;
      localStorage.setItem(`${version}_${ACCESS_TOKEN}`, data.access_token);
      localStorage.setItem(`${version}_${REFRESH_TOKEN}`, data.refresh_token);
      setAuthorizationToken(data.access_token);
      let companies: CompanyDbModel[] = [];
      const payload: JwtUserPayload = jwtDecode(data.access_token);
      const customPayload: CustomJwtUserPayload = { ...payload, companies: [] };
      try {
        const response = await http(`${BACKEND_ENDPOINTS.COMPANIES}`, {
          method: 'GET',
        });
        companies = response.data.data.companies;
        customPayload.companies = companies;
      } catch (e) {
        if (e instanceof AxiosError) {
          throw Error(e.response?.data.error.message);
        }
      }
      return customPayload;
    }
    return {
      otp_required: data.otp_required,
      email,
    };
  }
);
export const LOGIN_OTP_VERIFY = createAsyncThunk<
  CustomJwtUserPayload | null,
  { otp_code: string; email: string }
>(ActionsEnum.LOGIN_OTP_VERIFY, async ({ otp_code, email }, thunkAPI) => {
  let backendVersion: string = '';
  let data;
  try {
    const response = await http(`${BACKEND_ENDPOINTS.LOGIN_OTP_VERIFY}`, {
      method: 'POST',
      data: {
        otp_code,
        email,
      },
    });
    backendVersion = response.headers['x-sv'];
    data = response.data.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      return thunkAPI.rejectWithValue(e.response?.data.error.code);
    }
  }
  if (data.access_token && data.refresh_token) {
    const version = `${backendVersion}_${HASHED_APP_VERSION}`;
    localStorage.setItem(`${version}_${ACCESS_TOKEN}`, data.access_token);
    localStorage.setItem(`${version}_${REFRESH_TOKEN}`, data.refresh_token);
    setAuthorizationToken(data.access_token);
    let companies: CompanyDbModel[] = [];
    const payload: JwtUserPayload = jwtDecode(data.access_token);
    const customPayload: CustomJwtUserPayload = { ...payload, companies: [] };
    try {
      const response = await http(`${BACKEND_ENDPOINTS.COMPANIES}`, {
        method: 'GET',
      });
      companies = response.data.data.companies;
      customPayload.companies = companies;
    } catch (e) {
      if (e instanceof AxiosError) {
        throw Error(e.response?.data.error.message);
      }
    }
    return customPayload;
  }
  thunkAPI.dispatch(setErrorMessage({ message: 'loginOtpCodeVerify.token.error' }));
  return null;
});

export const LOGOUT = createAsyncThunk(ActionsEnum.LOGOUT, async () => {
  try {
    await http(BACKEND_ENDPOINTS.LOGOUT, {
      method: 'POST',
    });
  } catch (e) {
    if (e instanceof AxiosError) {
      throw Error(e.response?.data.error.message);
    }
  }
  flushTokens();
  setAuthorizationToken(null);
  return true;
});

export const RECOVERY = createAsyncThunk(
  ActionsEnum.REQUEST_RECOVERY,
  async ({ email, token }: RecoveryPayloadModel, thunkAPI): Promise<string> => {
    try {
      await http(`${BACKEND_ENDPOINTS.RECOVERY}/${email}`, {
        method: 'POST',
        data: {
          token,
        },
      });
    } catch (e) {
      thunkAPI.dispatch(setErrorMessage({ message: 'recovery.reject' }));
      if (e instanceof AxiosError) {
        throw Error(e.response?.data.error.message);
      }
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'recovery.confirm' }));
    return email;
  }
);

export const VERIFY = createAsyncThunk<string, VerifyPayloadModel>(
  ActionsEnum.VERIFY_OTP,
  async ({ email, otp }, thunkAPI) => {
    try {
      await http(`${BACKEND_ENDPOINTS.RECOVERY}/${email}/verify`, {
        method: 'POST',
        data: {
          type: AccountTypeEnum.HR,
          otp,
        },
      });
    } catch (e) {
      if (e instanceof AxiosError) {
        return thunkAPI.rejectWithValue(e.response?.data.error.code);
      }
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'publicOtpVerify.confirm' }));
    return otp;
  }
);

export const RESET_PASSWORD = createAsyncThunk<void, ResetPasswordPayload>(
  ActionsEnum.RESET_PASSWORD,
  async ({ resetCode, password }, thunkApi) => {
    try {
      await http(`${BACKEND_ENDPOINTS.RECOVERY}/${resetCode}`, {
        method: 'PUT',
        data: {
          password,
        },
      });
      thunkApi.dispatch(setSuccessMessage({ message: 'publicChangePassword.confirm' }));
    } catch (e) {
      if (e instanceof AxiosError) {
        const errorCode = e.response?.data.error.code;
        // New password same as the old one
        if (errorCode === '086') {
          thunkApi.dispatch(setErrorMessage({ message: 'publicChangePassword.samePassword' }));
          // Reset link already used
        } else if (errorCode === '091') {
          thunkApi.dispatch(setErrorMessage({ message: 'publicChangePassword.linkExpired' }));
        } else {
          thunkApi.dispatch(setErrorMessage({ message: 'publicChangePassword.reject' }));
        }
        return thunkApi.rejectWithValue(errorCode);
      }
    }
  }
);

export const CHANGE_AVATAR = createAsyncThunk(
  ActionsEnum.CHANGE_AVATAR,
  async (avatar: File, thunkAPI): Promise<JwtUserPayload> => {
    let backendVersion: string = '';
    let data;
    try {
      const formData = new FormData();
      formData.append('avatar', avatar);
      const response = await http(`${BACKEND_ENDPOINTS.PROFILE}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      backendVersion = response.headers['x-sv'];
      data = response.data.data;
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'changeAccount.avatar.reject' }));
      throw Error(e.response.data.error.message);
    }
    const version = `${backendVersion}_${HASHED_APP_VERSION}`;
    localStorage.setItem(`${version}_${ACCESS_TOKEN}`, data.access_token);
    setAuthorizationToken(data.access_token);
    return jwtDecode(data.access_token);
  }
);

export const CHANGE_ACCOUNT_INFO = createAsyncThunk(
  ActionsEnum.CHANGE_ACCOUNT_INFO,
  async (
    { name, surname }: { name?: string; surname?: string },
    thunkAPI
  ): Promise<JwtUserPayload> => {
    let backendVersion: string = '';
    let data;
    try {
      const response = await http(`${BACKEND_ENDPOINTS.PROFILE}`, {
        method: 'POST',
        data: {
          name,
          surname,
        },
      });
      backendVersion = response.headers['x-sv'];
      data = response.data.data;
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'changeAccount.info.reject' }));
      throw Error(e.response.data.error.message);
    }
    const version = `${backendVersion}_${HASHED_APP_VERSION}`;
    localStorage.setItem(`${version}_${ACCESS_TOKEN}`, data.access_token);
    setAuthorizationToken(data.access_token);
    return jwtDecode(data.access_token);
  }
);

export const REQUEST_CHANGE_PASSWORD_OTP_CODE = createAsyncThunk(
  ActionsEnum.REQUEST_CHANGE_PASSWORD_OTP_CODE,
  async (resend: boolean, thunkApi) => {
    try {
      await http(`${BACKEND_ENDPOINTS.RECOVERY}/secure`, { method: 'POST' });
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    if (resend) {
      thunkApi.dispatch(setSuccessMessage({ message: 'requestChangePassword.confirm' }));
    }
    return true;
  }
);

export const VERIFY_CHANGE_PASSWORD_OTP_CODE = createAsyncThunk<string, string>(
  ActionsEnum.VERIFY_CHANGE_PASSWORD_OTP_CODE,
  async (otp, thunkApi) => {
    try {
      await http(`${BACKEND_ENDPOINTS.RECOVERY}/secure/verify`, {
        method: 'POST',
        data: {
          otp,
        },
      });
    } catch (e) {
      if (e instanceof AxiosError) {
        return thunkApi.rejectWithValue(e.response?.data.error.code);
      }
    }
    thunkApi.dispatch(setSuccessMessage({ message: 'otpCodeVerification.confirm' }));
    return otp;
  }
);

export const SET_NEW_PASSWORD = createAsyncThunk(
  ActionsEnum.SET_NEW_PASSWORD,
  async ({ password, otp }: { password: string; otp: string }, thunkApi) => {
    try {
      await http(`${BACKEND_ENDPOINTS.RECOVERY}/secure`, {
        method: 'PUT',
        data: {
          password,
          otp,
        },
      });
    } catch (e: any) {
      if (e instanceof AxiosError) {
        const errorCode = e.response?.data.error.code;
        if (errorCode === '086') {
          thunkApi.dispatch(setErrorMessage({ message: 'publicChangePassword.samePassword' }));
        } else {
          thunkApi.dispatch(setErrorMessage({ message: 'publicChangePassword.reject' }));
        }
        return thunkApi.rejectWithValue(e.response?.data.error.code);
      }
    }
    thunkApi.dispatch(setSuccessMessage({ message: 'changePassword.confirm' }));
    return true;
  }
);

export const CHANGE_TEMPORARY_PASSWORD = createAsyncThunk(
  ActionsEnum.CHANGE_TEMPORARY_PASSWORD,
  async (password: string, thunkAPI): Promise<JwtUserPayload> => {
    let backendVersion: string = '';
    let data;
    try {
      const response = await http(`${BACKEND_ENDPOINTS.PROFILE}/password`, {
        method: 'PUT',
        data: {
          password,
        },
      });
      backendVersion = response.headers['x-sv'];
      data = response.data.data;
      thunkAPI.dispatch(setSuccessMessage({ message: 'changeTemporaryPassword.confirm' }));
    } catch (_e) {
      thunkAPI.dispatch(setSuccessMessage({ message: 'changeTemporaryPassword.reject' }));
    }
    const version = `${backendVersion}_${HASHED_APP_VERSION}`;
    localStorage.setItem(`${version}_${ACCESS_TOKEN}`, data.access_token);
    setAuthorizationToken(data.access_token);
    return jwtDecode(data.access_token);
  }
);

export const CHANGE_COMPANY_DETAILS = createAsyncThunk(
  ActionsEnum.CHANGE_COMPANY_DETAILS,
  async (payload: CompanyDetailsPayload, thunkAPI): Promise<CompanyDbModel[]> => {
    const { id, ...rest } = payload as any;
    try {
      const formData = new FormData();

      for (const prop in rest) {
        formData.append(prop === 'avatar' ? 'logo' : prop, rest[prop]);
      }

      await http(`${BACKEND_ENDPOINTS.COMPANIES}/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'changeCompany.details.reject' }));
      throw Error(e.response.data.error.message);
    }
    let companies: CompanyDbModel[] = [];
    try {
      const response = await http(`${BACKEND_ENDPOINTS.COMPANIES}`, {
        method: 'GET',
      });
      companies = response.data.data.companies;
    } catch (e) {
      if (e instanceof AxiosError) {
        throw Error(e.response?.data.error.message);
      }
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'changeCompany.details.confirm' }));
    return companies;
  }
);

export const UPLOAD_CUSTOM_PRIVACY_POLICY = createAsyncThunk(
  ActionsEnum.UPLOAD_CUSTOM_PRIVACY_POLICY,
  async ({ id, policy }: { id: number; policy: File }, thunkAPI): Promise<CompanyDbModel[]> => {
    try {
      const formData = new FormData();
      formData.append('policy', policy);

      await http(`${BACKEND_ENDPOINTS.COMPANIES}/${id}/privacy-policy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'upload.customPrivacyPolicy.reject' }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'upload.customPrivacyPolicy.confirm' }));
    let companies: CompanyDbModel[] = [];
    try {
      const response = await http(`${BACKEND_ENDPOINTS.COMPANIES}`, {
        method: 'GET',
      });
      companies = response.data.data.companies;
    } catch (e) {
      if (e instanceof AxiosError) {
        throw Error(e.response?.data.error.message);
      }
    }
    return companies;
  }
);

export const DELETE_CUSTOM_PRIVACY_POLICY = createAsyncThunk(
  ActionsEnum.DELETE_CUSTOM_PRIVACY_POLICY,
  async ({ id }: { id: number }, thunkAPI): Promise<CompanyDbModel[]> => {
    try {
      await http(`${BACKEND_ENDPOINTS.COMPANIES}/${id}/privacy-policy`, {
        method: 'DELETE',
      });
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'delete.customPrivacyPolicy.reject' }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'delete.customPrivacyPolicy.confirm' }));
    let companies: CompanyDbModel[] = [];
    try {
      const response = await http(`${BACKEND_ENDPOINTS.COMPANIES}`, {
        method: 'GET',
      });
      companies = response.data.data.companies;
    } catch (e) {
      if (e instanceof AxiosError) {
        throw Error(e.response?.data.error.message);
      }
    }
    return companies;
  }
);

export const SAML_RESUME = createAsyncThunk(
  ActionsEnum.SAML_RESUME,
  async ({ sh }: { sh: string }, thunkAPI): Promise<CustomJwtUserPayload> => {
    let backendVersion: string = '';
    let data;
    try {
      const response = await http(`${BACKEND_ENDPOINTS.LOGIN}/saml/resume`, {
        method: 'POST',
        data: {
          sh,
        },
      });
      backendVersion = response.headers['x-sv'];
      data = response.data.data;
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'wrongPassword' }));
      throw Error(e.response.data.error.message);
    }
    const version = `${backendVersion}_${HASHED_APP_VERSION}`;
    localStorage.setItem(`${version}_${ACCESS_TOKEN}`, data.access_token);
    localStorage.setItem(`${version}_${REFRESH_TOKEN}`, data.refresh_token);
    setAuthorizationToken(data.access_token);
    let companies: CompanyDbModel[] = [];
    const payload: JwtUserPayload = jwtDecode(data.access_token);
    const customPayload: CustomJwtUserPayload = { ...payload, companies: [] };
    try {
      const response = await http(`${BACKEND_ENDPOINTS.COMPANIES}`, {
        method: 'GET',
      });
      companies = response.data.data.companies;
      customPayload.companies = companies;
    } catch (e) {
      if (e instanceof AxiosError) {
        throw Error(e.response?.data.error.message);
      }
    }
    return customPayload;
  }
);

export const STORE_USER = createAsyncThunk(
  ActionsEnum.STORE_USER,
  async (accessToken: string): Promise<CustomJwtUserPayload> => {
    let companies: CompanyDbModel[] = [];
    const payload: JwtUserPayload = jwtDecode(accessToken);
    const customPayload: CustomJwtUserPayload = { ...payload, companies: [] };
    try {
      const response = await http(`${BACKEND_ENDPOINTS.COMPANIES}`, {
        method: 'GET',
      });
      companies = response.data.data.companies;
      customPayload.companies = companies;
    } catch (e) {
      if (e instanceof AxiosError) {
        throw Error(e.response?.data.error.message);
      }
    }
    return customPayload;
  }
);
