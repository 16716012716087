import { createAction } from '@reduxjs/toolkit';
import { ActionsEnum } from '../../enums/actions.enum';
import { ImportedCandidate, ImportedCandidateWithId } from '../../models/importedCandidate.model';

export const IMPORTS_SLICE = 'Imports';

export const ADD_CANDIDATE_PREVIEW = createAction<ImportedCandidate>(
  ActionsEnum.ADD_CANDIDATE_PREVIEW
);
export const EDIT_CANDIDATE_PREVIEW = createAction<ImportedCandidateWithId>(
  ActionsEnum.EDIT_CANDIDATE_PREVIEW
);
export const REMOVE_CANDIDATE_FROM_PREVIEW = createAction<string>(
  ActionsEnum.REMOVE_CANDIDATE_FROM_PREVIEW
);
export const FLUSH_IMPORT_CANDIDATE_PREVIEW = createAction(
  ActionsEnum.FLUSH_IMPORT_CANDIDATE_PREVIEW
);
