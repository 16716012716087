import { TeamMemberModel } from '../models/teamMember.model';
import { getOtpCode } from '../store/auth/selectors';
import {
  REQUEST_CHANGE_PASSWORD_OTP_CODE,
  SET_NEW_PASSWORD,
  VERIFY_CHANGE_PASSWORD_OTP_CODE,
} from '../store/auth/thunk';
import { useAppDispatch, useAppSelector } from '../store/store';
import {
  closeAllModals,
  openAddTeamMemberModal,
  openChangePasswordOTPModal,
  openDeleteCandidateModal,
  openDeleteTeamMemberModal,
  openEditCandidateModal,
} from '../store/ui/actions';
import {
  getAddTeamMemberModalStatus,
  getChangePasswordOTPModalStatus,
  getDeleteCandidateModalStatus,
  getDeleteTeamMemberModalStatus,
  getEditCandidateModalStatus,
  getOverlayStatus,
} from '../store/ui/selectors';

interface HookProps {
  overlay: boolean;
  changePasswordOTP: boolean;
  addTeamMember: boolean;
  deleteTeamMember: boolean;
  editCandidate: boolean;
  deleteCandidate: boolean;
  closeAllModalsHandler: () => void;
  requestNewOTPCodeHandler: () => void;
  openChangePasswordOTPModalHandler: () => void;
  openAddTeamMemberHandler: () => void;
  openDeleteTeamMemberHandler: (member: TeamMemberModel) => void;
  verifyOTPCodeHandler: (otp: string) => void;
  setNewPasswordHandler: (password: string) => void;
  openEditCandidateHandler: () => void;
  openDeleteCandidateHandler: () => void;
}

const useModals = (): HookProps => {
  const dispatch = useAppDispatch();

  const overlay = useAppSelector(getOverlayStatus);
  const changePasswordOTP = useAppSelector(getChangePasswordOTPModalStatus);
  const addTeamMember = useAppSelector(getAddTeamMemberModalStatus);
  const deleteTeamMember = useAppSelector(getDeleteTeamMemberModalStatus);
  const editCandidate = useAppSelector(getEditCandidateModalStatus);
  const deleteCandidate = useAppSelector(getDeleteCandidateModalStatus);
  const otp = useAppSelector(getOtpCode);

  const closeAllModalsHandler = () => dispatch(closeAllModals());

  const requestNewOTPCodeHandler = () => dispatch(REQUEST_CHANGE_PASSWORD_OTP_CODE(true));
  const openChangePasswordOTPModalHandler = () => {
    dispatch(REQUEST_CHANGE_PASSWORD_OTP_CODE(false));
    dispatch(openChangePasswordOTPModal());
  };
  const openAddTeamMemberHandler = () => dispatch(openAddTeamMemberModal());
  const openDeleteTeamMemberHandler = (member: TeamMemberModel) =>
    dispatch(openDeleteTeamMemberModal(member));
  const verifyOTPCodeHandler = (otp: string) => dispatch(VERIFY_CHANGE_PASSWORD_OTP_CODE(otp));
  const setNewPasswordHandler = (password: string) => {
    if (otp) {
      dispatch(SET_NEW_PASSWORD({ password, otp }));
    }
  };
  const openEditCandidateHandler = () => dispatch(openEditCandidateModal());
  const openDeleteCandidateHandler = () => dispatch(openDeleteCandidateModal());

  return {
    overlay,
    changePasswordOTP,
    addTeamMember,
    deleteTeamMember,
    editCandidate,
    deleteCandidate,
    requestNewOTPCodeHandler,
    openChangePasswordOTPModalHandler,
    openAddTeamMemberHandler,
    openDeleteTeamMemberHandler,
    closeAllModalsHandler,
    verifyOTPCodeHandler,
    setNewPasswordHandler,
    openEditCandidateHandler,
    openDeleteCandidateHandler,
  };
};

export default useModals;
