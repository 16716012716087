import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ImportedCandidate, ImportedCandidateWithId } from '../../models/importedCandidate.model';
import { closeAllModals } from '../ui/actions';
import {
  ADD_CANDIDATE_PREVIEW,
  EDIT_CANDIDATE_PREVIEW,
  FLUSH_IMPORT_CANDIDATE_PREVIEW,
  IMPORTS_SLICE,
  REMOVE_CANDIDATE_FROM_PREVIEW,
} from './actions';
import { IMPORT_CANDIDATES, IMPORT_CANDIDATES_PREVIEW } from './thunk';

export interface ImportsState {
  loading: boolean;
  imported: boolean;
  candidates: ImportedCandidateWithId[];
}

const ImportsInitialState: ImportsState = {
  loading: false,
  imported: false,
  candidates: [],
};

const slice = createSlice({
  name: IMPORTS_SLICE,
  initialState: ImportsInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(IMPORT_CANDIDATES.pending, (state, _) => ({
        ...state,
        loading: true,
        imported: false,
      }))
      .addCase(IMPORT_CANDIDATES.fulfilled, (state, _) => ({
        ...state,
        loading: false,
        imported: true,
        candidates: [],
      }))
      .addCase(IMPORT_CANDIDATES.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(IMPORT_CANDIDATES_PREVIEW.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        IMPORT_CANDIDATES_PREVIEW.fulfilled,
        (state, action: PayloadAction<ImportedCandidateWithId[]>) => ({
          ...state,
          loading: false,
          candidates: action.payload.map((candidate: ImportedCandidate) => ({
            ...candidate,
            id: Math.random().toString(),
          })),
        })
      )
      .addCase(IMPORT_CANDIDATES_PREVIEW.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(ADD_CANDIDATE_PREVIEW, (state, action: PayloadAction<ImportedCandidate>) => ({
        ...state,
        candidates: [...state.candidates, { ...action.payload }].map(
          (candidate: ImportedCandidate) => ({
            ...candidate,
            id: Math.random().toString(),
          })
        ),
      }))
      .addCase(EDIT_CANDIDATE_PREVIEW, (state, action: PayloadAction<ImportedCandidateWithId>) => {
        const candidates: ImportedCandidateWithId[] = [...state.candidates];
        const index: number = candidates.findIndex(
          (candidate: ImportedCandidateWithId) => candidate.email === action.payload.email
        );
        if (index > -1) {
          candidates[index] = { ...action.payload };
        }
        return {
          ...state,
          candidates,
        };
      })
      .addCase(REMOVE_CANDIDATE_FROM_PREVIEW, (state, action: PayloadAction<string>) => {
        const candidates = [...state.candidates].filter(
          (candidate: ImportedCandidateWithId) => candidate.email !== action.payload
        );
        return {
          ...state,
          candidates:
            candidates.filter((candidate: ImportedCandidateWithId) => !candidate.discarded)
              .length === 0
              ? []
              : candidates,
        };
      })
      .addCase(closeAllModals, (state, _) => ({
        ...state,
        candidates: [],
      }))
      .addCase(FLUSH_IMPORT_CANDIDATE_PREVIEW, (state, _) => ({
        ...state,
        candidates: [],
        imported: false,
      })),
});

export default slice.reducer;
