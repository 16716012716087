import { RootState } from '../store';
import { AuthState } from './reducer';

import { createSelector } from '@reduxjs/toolkit';

const AuthStore = (state: RootState) => state.auth;

export const getAuthLoading = createSelector(
  AuthStore,
  (authState: AuthState) => authState.loading
);

export const getLogged = createSelector(AuthStore, (authState: AuthState) => authState.logged);

export const getRecoveryDone = createSelector(
  AuthStore,
  (authState: AuthState) => authState.recoveryDone
);

export const getRecoveryEmail = createSelector(
  AuthStore,
  (authState: AuthState) => authState.recoveryEmail
);

export const getOtpVerified = createSelector(
  AuthStore,
  (authState: AuthState) => authState.otpVerified
);

export const getOtpRequired = createSelector(
  AuthStore,
  (authState: AuthState) => authState.otpRequired
);

export const getOtpLoading = createSelector(
  AuthStore,
  (authState: AuthState) => authState.otpLoading
);

export const getIsOtpWrong = createSelector(
  AuthStore,
  (authState: AuthState) => authState.isOtpWrong
);

export const getIsOtpLimitReached = createSelector(
  AuthStore,
  (authState: AuthState) => authState.isOtpLimitReached
);

export const getLoginEmail = createSelector(
  AuthStore,
  (authState: AuthState) => authState.loginEmail
);

export const getOtpCode = createSelector(AuthStore, (authState: AuthState) => authState.otpCode);

export const getOtpCodeLoading = createSelector(
  AuthStore,
  (authState: AuthState) => authState.otpRequestLoading
);

export const getPasswordChanged = createSelector(
  AuthStore,
  (authState: AuthState) => authState.passwordChanged
);

export const getPasswordResetSuccess = createSelector(
  AuthStore,
  (authState: AuthState) => authState.passwordResetSuccess
);

export const getAuthUser = createSelector(AuthStore, (authState: AuthState) => authState.user);

export const getUserName = createSelector(
  AuthStore,
  (authState: AuthState) => authState.user?.name
);

export const getUserSurname = createSelector(
  AuthStore,
  (authState: AuthState) => authState.user?.surname
);

export const getUserEmail = createSelector(
  AuthStore,
  (authState: AuthState) => authState.user?.email
);

export const getUserAvatar = createSelector(
  AuthStore,
  (authState: AuthState) => authState.user?.avatar
);

export const getUserCompanies = createSelector(
  AuthStore,
  (authState: AuthState) => authState.companies
);

export const getUserPermissions = createSelector(
  AuthStore,
  (authState: AuthState) => authState.user?.capabilities?.permissions
);

export const getSelectedCompany = createSelector(
  AuthStore,
  (authState: AuthState) => authState.selectedCompany
);

export const getPasswordChangeRequired = createSelector(
  AuthStore,
  (authState: AuthState) => authState.user?.require_password_change
);

export const getWasSaml = createSelector(AuthStore, (authState: AuthState) => authState.was_saml);

export const getUserRole = createSelector(getAuthUser, (user) => user?.capabilities?.role);
