import { ACCESS_TOKEN, HASHED_APP_VERSION, REFRESH_TOKEN } from '../constants/auth';

type Tokens = Record<string, string> | undefined;

const ACCESS_TOKEN_KEY = `${ACCESS_TOKEN}_key`;

const REFRESH_TOKEN_KEY = `${REFRESH_TOKEN}_key`;

export const getHashedTokensKeysFromLocalStorage = (): Tokens => {
  let tokens: Tokens;
  const storage_items = localStorage.length;
  for (let i = 0; i < storage_items; i++) {
    const key = localStorage.key(i);
    if (key) {
      const item = localStorage.getItem(key);
      if (item) {
        if (!tokens) tokens = {};
        tokens = {
          ...tokens,
          [key.includes(ACCESS_TOKEN) ? ACCESS_TOKEN_KEY : REFRESH_TOKEN_KEY]: key,
        };
      }
    }
  }
  return tokens;
};

export const getBackendHashedKey = (): string | null => {
  const tokens = getHashedTokensKeysFromLocalStorage();
  if (!tokens || !tokens[ACCESS_TOKEN_KEY] || !tokens[REFRESH_TOKEN_KEY]) {
    return null;
  }
  return tokens[ACCESS_TOKEN_KEY].split('_')[0];
};

export const getToken = (
  type: 'access_token' | 'refresh_token' = 'access_token'
): string | null => {
  const backendHashedKey = getBackendHashedKey();
  if (!backendHashedKey) return null;
  const tokenKey = `${backendHashedKey}_${HASHED_APP_VERSION}_${type === 'access_token' ? ACCESS_TOKEN : REFRESH_TOKEN}`;
  const token = localStorage.getItem(tokenKey);
  return token ? token : null;
};

export const flushTokens = (): void => {
  let tokens_keys: string[] = [];
  const storage_items = localStorage.length;
  for (let i = 0; i < storage_items; i++) {
    const key = localStorage.key(i);
    if (key) {
      const item = localStorage.getItem(key);
      if (item) {
        const isToken = key.includes('token');
        if (isToken) {
          tokens_keys = [...tokens_keys, key];
        }
      }
    }
  }
  tokens_keys.forEach((key: string) => localStorage.removeItem(key));
};
