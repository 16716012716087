import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { BACKEND_ENDPOINTS } from '../../constants/endpoints';
import { ActionsEnum } from '../../enums/actions.enum';
import { TeamMembersResponse } from '../../models/ThunksInterfaces/retrieveTeamMembers';
import { http } from '../../utils/http';
import { setErrorMessage, setSuccessMessage } from '../popup/actions';

export const RETRIEVE_TEAM_MEMBERS = createAsyncThunk(
  ActionsEnum.RETRIEVE_TEAM_MEMBERS,
  async (companyId: number): Promise<TeamMembersResponse> => {
    let data: TeamMembersResponse;
    try {
      const response = await http(BACKEND_ENDPOINTS.TEAM, {
        method: 'GET',
        params: {
          companyId,
        },
      } as AxiosRequestConfig);
      data = response.data.data;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);

export const ADD_TEAM_MEMBER = createAsyncThunk(
  ActionsEnum.ADD_TEAM_MEMBER,
  async (
    {
      companyId,
      name,
      surname,
      email,
    }: {
      companyId: number;
      name: string;
      surname: string;
      email: string;
    },
    thunkAPI
  ): Promise<TeamMembersResponse> => {
    let data: TeamMembersResponse;
    try {
      const response = await http(BACKEND_ENDPOINTS.TEAM, {
        method: 'POST',
        data: {
          companyId,
          name,
          surname,
          email,
        },
      } as AxiosRequestConfig);
      data = response.data.data;
    } catch (e: any) {
      if (e.response.data.error.message === 'user-already-has-access') {
        thunkAPI.dispatch(setErrorMessage({ message: 'account.team.addNew.teamMember.duplicate' }));
      } else {
        thunkAPI.dispatch(setErrorMessage({ message: 'account.team.addNew.teamMember.reject' }));
      }
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'account.team.addNew.teamMember.confirm' }));
    return data;
  }
);

export const DELETE_TEAM_MEMBER = createAsyncThunk(
  ActionsEnum.DELETE_TEAM_MEMBER,
  async ({
    companyId,
    memberId,
  }: {
    companyId: number;
    memberId: number;
  }): Promise<TeamMembersResponse> => {
    let data: TeamMembersResponse;
    try {
      const response = await http(`${BACKEND_ENDPOINTS.TEAM}/${memberId}`, {
        method: 'DELETE',
        params: {
          companyId,
        },
      } as AxiosRequestConfig);
      data = response.data.data;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);
