import { createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_ENDPOINTS } from '../../constants/endpoints';
import { ActionsEnum } from '../../enums/actions.enum';
import { ImportedCandidate, ImportedCandidateWithId } from '../../models/importedCandidate.model';
import { http } from '../../utils/http';
import { setErrorMessage, setSuccessMessage } from '../popup/actions';

export const IMPORT_CANDIDATES = createAsyncThunk(
  ActionsEnum.IMPORT_CANDIDATES,
  async (
    { jobPostId, candidates }: { jobPostId: string; candidates: ImportedCandidate[] },
    thunkAPI
  ): Promise<void> => {
    try {
      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/import`, {
        method: 'POST',
        data: {
          candidates: candidates
            .filter((candidate: ImportedCandidate) => !candidate.discarded)
            .map((candidate: ImportedCandidate) => {
              const obj: Partial<ImportedCandidate> = {
                name: candidate.name,
                surname: candidate.surname,
                email: candidate.email,
              };
              if (candidate.phone?.length > 0) {
                obj.phone = candidate.prefix
                  ? `${candidate.prefix}${candidate.phone}`
                  : `${candidate.phone}`;
              }
              return obj;
            }),
        },
      });
      const success: boolean = response.data.success;
      if (success) {
        thunkAPI.dispatch(setSuccessMessage({ message: 'applications.importCandidates.confirm' }));
      } else {
        thunkAPI.dispatch(setErrorMessage({ message: 'applications.importCandidates.reject' }));
      }
    } catch (e) {
      thunkAPI.dispatch(setErrorMessage({ message: 'applications.importCandidates.reject' }));
      throw e;
    }
    return;
  }
);

export const IMPORT_CANDIDATES_PREVIEW = createAsyncThunk(
  ActionsEnum.IMPORT_CANDIDATES_PREVIEW,
  async (
    { jobPostId, file }: { jobPostId: string; file: File },
    thunkAPI
  ): Promise<ImportedCandidateWithId[]> => {
    let data: ImportedCandidateWithId[];
    try {
      const formData = new FormData();
      formData.append('import', file);
      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/import/preview`, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      data = response.data.data;
      if (data.filter((el: ImportedCandidateWithId) => !el.discarded).length === 0) {
        thunkAPI.dispatch(
          setErrorMessage({ message: 'applications.importCandidatesByFile.fileNotValid' })
        );
      }
    } catch (e) {
      thunkAPI.dispatch(setErrorMessage({ message: 'applications.importCandidates.reject' }));
      throw e;
    }
    return data;
  }
);
