import { createSlice } from '@reduxjs/toolkit';
import { EDIT_RECRUITMENT_SLICE, resetEdit } from './actions';
import { EDIT_RECRUITMENT } from './thunk';

export interface EditRecruitmentState {
  loading: boolean;
  completed: boolean;
}

const EditRecruitmentInitialState: EditRecruitmentState = {
  loading: false,
  completed: false,
};

const slice = createSlice({
  name: EDIT_RECRUITMENT_SLICE,
  initialState: EditRecruitmentInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(resetEdit, (state, _) => ({
        ...state,
        completed: false,
      }))
      .addCase(EDIT_RECRUITMENT.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(EDIT_RECRUITMENT.fulfilled, (state, _) => ({
        ...state,
        loading: false,
        completed: true,
      }))
      .addCase(EDIT_RECRUITMENT.rejected, (state, _) => ({
        ...state,
        loading: false,
      })),
});

export default slice.reducer;
