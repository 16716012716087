export const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const CAMERA_BASE_URL = import.meta.env.VITE_ALGOINTERVIEW_BASE_URL;

export const BACKEND_ENDPOINTS = {
  STATUS: 'status/v1/health',
  LOGIN: 'auth/v1/login',
  LOGIN_OTP_VERIFY: 'auth/v1/login/otp-verify',
  LOGOUT: 'auth/v1/logout',
  RECOVERY: 'auth/v1/recovery',
  REFRESH_TOKEN: 'auth/v1/token',
  PROFILE: 'auth/v1/me',
  JOB_POSTS: 'jobs/v1/job-posts',
  RETRIEVE_AVAILABLE_SKILLS: '/skills/v1/soft-skills',
  RETRIEVE_HARD_SKILLS: '/skills/v1/hard-skills',
  RETRIEVE_CUSTOM_QUESTIONNAIRES: '/skills/v1/questionnaires',
  COMPANIES: '/entities/v1/companies',
  TEAM: '/entities/v1/hr',
  TEAM_FEEDBACKS: 'jobs/v1/team-feedback',
  LANGS: 'jobs/v1/languages',
  JOB_AREAS: 'jobs/v1/job-posts/screening-area',
  CUSTOM_QUESTIONNAIRE_IMAGES: 'fs/v1/custom-questionnaires/upload-image',
} as const;

export const GATEWAY_ENDPOINTS = {
  INTEGRATIONS: 'v1/integrations',
};
