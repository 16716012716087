import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LanguageDbModel } from '../../models/languageDb.model';
import { RETRIEVE_AVAILABLE_LANGUAGES } from './thunk';

export interface LanguagesState {
  languages: LanguageDbModel[];
}

const LanguagesInitialState: LanguagesState = {
  languages: [],
};

const LANGUAGES_SLICE = 'Languages';

const slice = createSlice({
  name: LANGUAGES_SLICE,
  initialState: LanguagesInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(RETRIEVE_AVAILABLE_LANGUAGES.pending, (state, _) => ({
        ...state,
      }))
      .addCase(
        RETRIEVE_AVAILABLE_LANGUAGES.fulfilled,
        (state, action: PayloadAction<LanguageDbModel[]>) => ({
          ...state,
          languages: action.payload,
        })
      )
      .addCase(RETRIEVE_AVAILABLE_LANGUAGES.rejected, (state, _) => ({
        ...state,
      })),
});

export default slice.reducer;
