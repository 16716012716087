import { createAction } from '@reduxjs/toolkit';
import { ActionsEnum } from '../../enums/actions.enum';
import { TeamMemberModel } from '../../models/teamMember.model';

export const closeAllModals = createAction(ActionsEnum.CLOSE_ALL_MODALS);
export const openChangePasswordOTPModal = createAction(ActionsEnum.OPEN_CHANGE_PASSWORD_OTP_MODAL);
export const openAddTeamMemberModal = createAction(ActionsEnum.OPEN_ADD_TEAM_MEMBER_MODAL);
export const openDeleteTeamMemberModal = createAction<TeamMemberModel>(
  ActionsEnum.OPEN_DELETE_TEAM_MEMBER_MODAL
);
export const closePopupBackdrop = createAction(ActionsEnum.CLOSE_POPUP_BACKDROP);
export const openEditCandidateModal = createAction(ActionsEnum.OPEN_EDIT_CANDIDATE_MODAL);
export const openDeleteCandidateModal = createAction(ActionsEnum.OPEN_DELETE_CANDIDATE_MODAL);

// Sidebar actions
export const toggleSidebarCollapse = createAction(ActionsEnum.TOGGLE_SIDEBAR_COLLAPSE);
export const enableSidebarCollapse = createAction(ActionsEnum.ENABLE_SIDEBAR_COLLAPSE);
export const disableSidebarCollapse = createAction(ActionsEnum.DISABLE_SIDEBAR_COLLAPSE);
