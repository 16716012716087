import { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
}

const InputGroup: FunctionComponent<Props> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default InputGroup;

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacer[16]};
`;
