import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  PASSWORD_REGEX,
  STRING_HAS_LOWERCASE_REGEX,
  STRING_HAS_MIN_LENGTH_REGEX,
  STRING_HAS_NUMBER_REGEX,
  STRING_HAS_SPECIAL_CHAR_REGEX,
  STRING_HAS_UPPERCASE_REGEX,
} from '../../../constants/regex';
import BlackButton from '../../Buttons/BlackButton';
import Input from '../../ui/FormsElements/Input/Input';
import InputGroup from '../../ui/FormsElements/InputGroup/InputGroup';
import Label from '../../ui/FormsElements/Label/Label';

interface Props {
  loading: boolean;
  onSubmit: (password: string) => void;
}

const ChangePasswordForm = ({ loading, onSubmit }: Props) => {
  const inputId = useId();
  const { t } = useTranslation(['recovery']);
  const [state, setState] = useState({
    password: { value: '', valid: false, touched: false },
    passwordConfirm: { value: '', valid: false, touched: false },
  });
  const [showPasswordValidations, setShowPasswordValidations] = useState(false);
  const [showPasswordMismatchError, setShowPasswordMismatchError] = useState(false);

  const formIsValid =
    state.password.valid &&
    state.passwordConfirm.valid &&
    state.password.value === state.passwordConfirm.value;

  const passwordHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!showPasswordValidations) {
      setShowPasswordValidations(true);
    }
    setState((state) => ({
      ...state,
      password: {
        value: e.target.value,
        valid: PASSWORD_REGEX.test(e.target.value),
        touched: true,
      },
    }));

    // Check if the password and confirm password are the same
    if (
      !showPasswordMismatchError &&
      e.target.value !== state.passwordConfirm.value &&
      state.passwordConfirm.value.length > 0
    ) {
      setShowPasswordMismatchError(true);
    } else if (showPasswordMismatchError && e.target.value === state.passwordConfirm.value) {
      setShowPasswordMismatchError(false);
    }
  };

  const passwordConfirmHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!showPasswordMismatchError && e.target.value !== state.password.value) {
      setShowPasswordMismatchError(true);
    } else if (showPasswordMismatchError && e.target.value === state.password.value) {
      setShowPasswordMismatchError(false);
    }
    setState((state) => ({
      ...state,
      passwordConfirm: {
        value: e.target.value,
        valid: e.target.value === state.password.value,
        touched: true,
      },
    }));
  };

  const submitHandler = () => {
    if (formIsValid) {
      onSubmit(state.password.value);
    }
  };

  return (
    <Form onSubmit={submitHandler}>
      <InputGroup>
        <Label
          htmlFor={`${inputId}-password`}
          label={t('password.label')}
        />
        <Input
          type="password"
          name={`${inputId}-password`}
          placeholder={t('password.placeholder')}
          disabled={false}
          value={state.password.value}
          onBlur={passwordHandler}
          onChange={passwordHandler}
        />
      </InputGroup>
      {showPasswordValidations && (
        <Validations>
          {/** At least one lowercase char */}
          <Validation $isValid={STRING_HAS_LOWERCASE_REGEX.test(state.password.value)}>
            <FontAwesomeIcon
              icon={STRING_HAS_LOWERCASE_REGEX.test(state.password.value) ? faCheck : faXmark}
              className="fa-fw"
            />
            {t('password.validation.lowercase')}
          </Validation>
          {/** At least one uppercase char */}
          <Validation $isValid={STRING_HAS_UPPERCASE_REGEX.test(state.password.value)}>
            <FontAwesomeIcon
              icon={STRING_HAS_UPPERCASE_REGEX.test(state.password.value) ? faCheck : faXmark}
              className="fa-fw"
            />
            {t('password.validation.uppercase')}
          </Validation>
          {/** At least a number */}
          <Validation $isValid={STRING_HAS_NUMBER_REGEX.test(state.password.value)}>
            <FontAwesomeIcon
              icon={STRING_HAS_NUMBER_REGEX.test(state.password.value) ? faCheck : faXmark}
              className="fa-fw"
            />
            {t('password.validation.number')}
          </Validation>
          {/** At least a special char */}
          <Validation $isValid={STRING_HAS_SPECIAL_CHAR_REGEX.test(state.password.value)}>
            <FontAwesomeIcon
              icon={STRING_HAS_SPECIAL_CHAR_REGEX.test(state.password.value) ? faCheck : faXmark}
              className="fa-fw"
            />
            {t('password.validation.special')}
          </Validation>
          {/** At least N chars */}
          <Validation $isValid={STRING_HAS_MIN_LENGTH_REGEX.test(state.password.value)}>
            <FontAwesomeIcon
              icon={STRING_HAS_MIN_LENGTH_REGEX.test(state.password.value) ? faCheck : faXmark}
              className="fa-fw"
            />
            {t('password.validation.length')}
          </Validation>
        </Validations>
      )}
      <InputGroup>
        <Label
          htmlFor={`${inputId}-passwordConfirm`}
          label={t('confirmPassword.label')}
        />
        <Input
          type="password"
          name={`${inputId}-passwordConfirm`}
          placeholder={t('confirmPassword.placeholder')}
          disabled={false}
          value={state.passwordConfirm.value}
          onBlur={passwordConfirmHandler}
          onChange={passwordConfirmHandler}
        />
      </InputGroup>
      {showPasswordMismatchError && state.passwordConfirm.value.length > 0 && (
        <Validations>
          <Validation $isValid={state.password.value === state.passwordConfirm.value}>
            <FontAwesomeIcon
              icon={state.password.value === state.passwordConfirm.value ? faCheck : faXmark}
              className="fa-fw"
            />
            {t('confirmPassword.validation.match')}
          </Validation>
        </Validations>
      )}
      <ButtonContainer>
        <BlackButton
          disabled={!formIsValid || loading}
          onClick={submitHandler}
        >
          {t('button.confirm')}
        </BlackButton>
      </ButtonContainer>
    </Form>
  );
};

export default ChangePasswordForm;

const Form = styled.form`
  width: 100%;
  max-width: 450px;
  label {
    font-weight: bold;
    color: ${({ theme }) => theme.lightUI.text.default};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    width: 100%;
    justify-content: center;
  }
`;

const Validations = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacer[8]};
  margin-bottom: ${({ theme }) => theme.spacer[16]};
`;

const Validation = styled.div<{ $isValid: boolean }>`
  display: flex;
  font-size: ${({ theme }) => theme.size[15]};
  color: ${({ theme, $isValid }) =>
    $isValid ? theme.lightUI.text.success : theme.lightUI.text.danger};
`;
