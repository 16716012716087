import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const { VITE_I18N_DEBUG } = import.meta.env;

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    returnNull: false, // https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
    debug: VITE_I18N_DEBUG === 'true',
    load: 'languageOnly', // Read 'navigator.language' as language string -> en-XX = en; default 'all'
    detection: { caches: [] }, // Disable language detection cache; default ['localStorage']
    ns: [], // Disable loading of (missing) `translation.json` files. NOTE: don't work if no namespaces are passed to useTranslation hook.
    fallbackLng: ['it', 'en'],
    // preload: [ 'it', 'en' ],
    supportedLngs: ['it', 'en'],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

// eslint-disable-next-line @typescript-eslint/no-empty-function
i18n.loadNamespaces('softSkills', (_err, _t) => {});

export default i18n;
