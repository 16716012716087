import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { HASHED_APP_VERSION, REFRESH_TOKEN } from '../constants/auth';
import { BACKEND_ENDPOINTS, BASE_URL } from '../constants/endpoints';
import { getBackendHashedKey, getToken } from './localStorage';

const LOGOUT_ERROR_CODES: string[] = [
  '003', // ACCESS_TOKEN_EXPIRED
  // '005', // REFRESH_TOKEN_EXPIRED
  '048', // SESSION_NOT_FOUND
  // '050', // ACCESS_TOKEN_MISSING
];

const instance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setAuthorizationToken = (token: string | null = null): void => {
  if (token && token.trim()) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common['Authorization'];
  }
};

const handleErrorCode = async (error: AxiosError) => {
  const { data, config, status } = error.response as AxiosResponse;

  // Temporary fix for login loop
  if (
    status === 401 &&
    window.location.pathname !== '/public/login' &&
    // OTP codes
    !['027', '083'].includes(data.error.code)
  ) {
    localStorage.clear();
    window.location.href = '/public/login';
  }

  if (LOGOUT_ERROR_CODES.includes(data?.error?.code)) {
    if (data?.error?.code === '048') {
      localStorage.clear();
      window.location.reload();
    } else {
      let response;
      try {
        response = await http(BACKEND_ENDPOINTS.REFRESH_TOKEN, {
          method: 'PUT',
          data: {
            refresh_token: getToken('refresh_token'),
          },
        });
      } catch (_e) {
        localStorage.clear();
        window.location.reload();
      }
      if (response && response.data.success) {
        const refreshedAccessToken = response.data.data.access_token;
        const refreshTokenKey = `${getBackendHashedKey()}_${HASHED_APP_VERSION}_${REFRESH_TOKEN}`;
        localStorage.setItem(refreshTokenKey, refreshedAccessToken);
        setAuthorizationToken(refreshedAccessToken);
        return instance.request({
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${refreshedAccessToken}`,
          },
        });
      } else {
        localStorage.clear();
        window.location.reload();
      }
    }
  }
  return Promise.reject(error);
};

export const setAuthInterceptor = (): void => {
  console.log('[setAuthInterceptor] Bootstrap jwt interceptor...');
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => handleErrorCode(error)
  );
};

export const http = async (endpoint: string, config: AxiosRequestConfig) =>
  instance.request({ url: endpoint, ...config });

export const downloadFile = async (
  url: string,
  mimetype: string,
  filename: string,
  successCb: () => void,
  errorCb: (e: unknown) => void
): Promise<void> => {
  let link: HTMLAnchorElement;
  const id = `download-link-${DateTime.local().toISO()}`;
  try {
    const res = await fetch(url);
    const data = await res.blob();
    const blob = new Blob([data], { type: mimetype });
    link = document.createElement('a');
    link.setAttribute('id', id);
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    successCb();
  } catch (e: unknown) {
    errorCb(e);
  } finally {
    const target = document.getElementById(id);
    if (target) {
      target.parentNode?.removeChild(target);
    }
  }
};
