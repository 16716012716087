import { createReducer } from '@reduxjs/toolkit';
import {
  DELETE_APPLICATION_STATE,
  SUBMIT_APPLICATION_STATE,
  UPDATE_APPLICATION,
  UPDATE_APPLICATION_STATE,
} from '../../features/applications/thunk';
import { SET_NEW_PASSWORD } from '../auth/thunk';
import { IMPORT_CANDIDATES } from '../imports/thunk';
import { ADD_TEAM_MEMBER, DELETE_TEAM_MEMBER } from '../team/thunk';
import {
  closeAllModals,
  closePopupBackdrop,
  disableSidebarCollapse,
  enableSidebarCollapse,
  openAddTeamMemberModal,
  openChangePasswordOTPModal,
  openDeleteCandidateModal,
  openDeleteTeamMemberModal,
  openEditCandidateModal,
  toggleSidebarCollapse,
} from './actions';

export interface UiState {
  overlay: boolean;
  changePasswordOTPModal: boolean;
  addTeamMemberModal: boolean;
  deleteTeamMemberModal: boolean;
  editCandidateModal: boolean;
  deleteCandidateModal: boolean;
  popupBackdrop: boolean;
  popups: {
    company: boolean;
    profileMenu: boolean;
    recruitmentOrderBy: boolean;
    applicationsOrderBy: boolean;
    applicationContacts: boolean;
    changeApplicationStates: boolean;
    kebabMenu: boolean;
  };
  sidebar: {
    collapsed: boolean;
  };
}

const UiInitialState: UiState = {
  overlay: false,
  changePasswordOTPModal: false,
  addTeamMemberModal: false,
  deleteTeamMemberModal: false,
  editCandidateModal: false,
  deleteCandidateModal: false,
  popupBackdrop: false,
  popups: {
    company: false,
    profileMenu: false,
    recruitmentOrderBy: false,
    applicationsOrderBy: false,
    applicationContacts: false,
    changeApplicationStates: false,
    kebabMenu: false,
  },
  sidebar: {
    collapsed: false,
  },
};

const reducerPopupMessage = createReducer(UiInitialState, (builder) =>
  builder
    .addCase(closeAllModals, (state, _) => ({
      ...state,
      overlay: false,
      changePasswordOTPModal: false,
      addTeamMemberModal: false,
      deleteTeamMemberModal: false,
      editCandidateModal: false,
      deleteCandidateModal: false,
    }))
    .addCase(openChangePasswordOTPModal, (state, _) => ({
      ...state,
      overlay: true,
      changePasswordOTPModal: true,
    }))
    .addCase(openAddTeamMemberModal, (state, _) => ({
      ...state,
      overlay: true,
      addTeamMemberModal: true,
    }))
    .addCase(openDeleteTeamMemberModal, (state, _) => ({
      ...state,
      overlay: true,
      deleteTeamMemberModal: true,
    }))
    .addCase(closePopupBackdrop, (state, _) => ({
      ...state,
      popupBackdrop: false,
      popups: {
        ...UiInitialState.popups,
      },
    }))
    .addCase(SET_NEW_PASSWORD.fulfilled, (state, _) => ({
      ...state,
      overlay: false,
      changePasswordOTPModal: false,
    }))
    .addCase(SET_NEW_PASSWORD.rejected, (state, _) => ({
      ...state,
      overlay: false,
      changePasswordOTPModal: false,
    }))
    .addCase(ADD_TEAM_MEMBER.fulfilled, (state, _) => ({
      ...state,
      overlay: false,
      addTeamMemberModal: false,
    }))
    .addCase(DELETE_TEAM_MEMBER.fulfilled, (state, _) => ({
      ...state,
      overlay: false,
      deleteTeamMemberModal: false,
    }))
    .addCase(SUBMIT_APPLICATION_STATE.fulfilled, (state, _) => ({
      ...state,
      overlay: false,
      addRecruitmentStageModal: false,
    }))
    .addCase(UPDATE_APPLICATION_STATE.fulfilled, (state, _) => ({
      ...state,
      overlay: false,
      editRecruitmentStageModal: false,
    }))
    .addCase(DELETE_APPLICATION_STATE.fulfilled, (state, _) => ({
      ...state,
      overlay: false,
      deleteRecruitmentStageModal: false,
    }))
    .addCase(IMPORT_CANDIDATES.fulfilled, (state, _) => ({
      ...state,
      overlay: false,
      importCandidatesModal: false,
    }))
    .addCase(openEditCandidateModal, (state, _) => ({
      ...state,
      overlay: true,
      editCandidateModal: true,
    }))
    .addCase(UPDATE_APPLICATION.fulfilled, (state, _) => ({
      ...state,
      overlay: false,
      editCandidateModal: false,
    }))
    .addCase(openDeleteCandidateModal, (state, _) => ({
      ...state,
      overlay: true,
      deleteCandidateModal: true,
    }))
    .addCase(toggleSidebarCollapse, (state, _) => ({
      ...state,
      sidebar: {
        collapsed: !state.sidebar.collapsed,
      },
    }))
    .addCase(enableSidebarCollapse, (state, _) => ({
      ...state,
      sidebar: {
        collapsed: true,
      },
    }))
    .addCase(disableSidebarCollapse, (state, _) => ({
      ...state,
      sidebar: {
        collapsed: false,
      },
    }))
);

export default reducerPopupMessage;
