import styled from 'styled-components';

interface Props {
  htmlFor: string;
  label: string;
}

const Label = ({ htmlFor, label }: Props) => {
  return <Container htmlFor={htmlFor}>{label}</Container>;
};

export default Label;

const Container = styled.label`
  display: block;
  width: 100%;
  font-size: ${({ theme }) => theme.size[15]};
  font-weight: 500;
  line-height: ${({ theme }) => theme.spacer[24]};
  margin-bottom: ${({ theme }) => theme.spacer[4]};
  color: ${({ theme }) => theme.lightUI.text.muted};
`;
