import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UiState } from './reducer';

const UiStore = (state: RootState) => state.ui;

export const getOverlayStatus = createSelector(UiStore, (state: UiState) => state.overlay);
export const getChangePasswordOTPModalStatus = createSelector(
  UiStore,
  (state: UiState) => state.changePasswordOTPModal
);
export const getAddTeamMemberModalStatus = createSelector(
  UiStore,
  (state: UiState) => state.addTeamMemberModal
);
export const getDeleteTeamMemberModalStatus = createSelector(
  UiStore,
  (state: UiState) => state.deleteTeamMemberModal
);
export const getPopupBackdrop = createSelector(UiStore, (state: UiState) => state.popupBackdrop);
export const getEditCandidateModalStatus = createSelector(
  UiStore,
  (state: UiState) => state.editCandidateModal
);
export const getDeleteCandidateModalStatus = createSelector(
  UiStore,
  (state: UiState) => state.deleteCandidateModal
);
export const getSidebarCollapsedState = createSelector(
  UiStore,
  (state: UiState) => state.sidebar.collapsed
);
