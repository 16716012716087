import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CustomQuestionnaire,
  HardSkill,
  SoftSkill,
} from '../../models/ThunksInterfaces/retrieveAvailableSkills';
import { RETRIEVE_AVAILABLE_LANGUAGES } from '../languages/thunk';
import {
  CLONE_CUSTOM_QUESTIONNAIRES,
  DELETE_CUSTOM_QUESTIONNAIRE,
  RETRIEVE_ALL_HARD_SKILLS,
  RETRIEVE_AVAILABLE_SKILLS,
  RETRIEVE_CUSTOM_QUESTIONNAIRES,
} from './thunk';

export interface SkillsState {
  loading: boolean;
  softSkills: SoftSkill[];
  hardSkills: HardSkill[];
  customQuestionnaires: CustomQuestionnaire[];
}

const SkillsInitialState: SkillsState = {
  loading: false,
  softSkills: [],
  hardSkills: [],
  customQuestionnaires: [],
};

const SKILLS_SLICE = 'Skills';

const slice = createSlice({
  name: SKILLS_SLICE,
  initialState: SkillsInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(RETRIEVE_AVAILABLE_SKILLS.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        RETRIEVE_AVAILABLE_SKILLS.fulfilled,
        (state, action: PayloadAction<SoftSkill[]>) => ({
          ...state,
          loading: false,
          softSkills: [...action.payload],
        })
      )
      .addCase(RETRIEVE_AVAILABLE_SKILLS.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(RETRIEVE_ALL_HARD_SKILLS.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(RETRIEVE_ALL_HARD_SKILLS.fulfilled, (state, action: PayloadAction<HardSkill[]>) => ({
        ...state,
        loading: false,
        hardSkills: [...action.payload],
      }))
      .addCase(RETRIEVE_ALL_HARD_SKILLS.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(RETRIEVE_CUSTOM_QUESTIONNAIRES.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        RETRIEVE_CUSTOM_QUESTIONNAIRES.fulfilled,
        (state, action: PayloadAction<CustomQuestionnaire[]>) => ({
          ...state,
          loading: false,
          customQuestionnaires: action.payload.map((cq) => ({
            ...cq,
            duration: cq.duration ? cq.duration / 60 : null, // Convert seconds to minutes
          })),
        })
      )
      .addCase(RETRIEVE_CUSTOM_QUESTIONNAIRES.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(DELETE_CUSTOM_QUESTIONNAIRE.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(DELETE_CUSTOM_QUESTIONNAIRE.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        customQuestionnaires: state.customQuestionnaires.filter((cq) => cq.id !== action.payload),
      }))
      .addCase(DELETE_CUSTOM_QUESTIONNAIRE.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(RETRIEVE_AVAILABLE_LANGUAGES.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(CLONE_CUSTOM_QUESTIONNAIRES.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(CLONE_CUSTOM_QUESTIONNAIRES.fulfilled, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(CLONE_CUSTOM_QUESTIONNAIRES.rejected, (state, _) => ({
        ...state,
        loading: false,
      })),
});

export default slice.reducer;
