import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CompanyDbModel } from '../../models/company.model';
import { CustomJwtUserPayload, JwtUserPayload } from '../../models/userDb.model';
import { AUTH_SLICE, changeCompany, resetUser } from './actions';
import {
  CHANGE_ACCOUNT_INFO,
  CHANGE_AVATAR,
  CHANGE_COMPANY_DETAILS,
  CHANGE_TEMPORARY_PASSWORD,
  DELETE_CUSTOM_PRIVACY_POLICY,
  LOGIN,
  LOGIN_OTP_VERIFY,
  LOGOUT,
  RECOVERY,
  REQUEST_CHANGE_PASSWORD_OTP_CODE,
  RESET_PASSWORD,
  SAML_RESUME,
  SET_NEW_PASSWORD,
  STORE_USER,
  UPLOAD_CUSTOM_PRIVACY_POLICY,
  VERIFY,
  VERIFY_CHANGE_PASSWORD_OTP_CODE,
} from './thunk';

export interface AuthState {
  loading: boolean;
  logged: boolean;
  otpRequestLoading: boolean;
  user: JwtUserPayload;
  companies: CompanyDbModel[];
  recoveryDone: boolean;
  recoveryEmail: string | null;
  loginEmail: string | null; // Email used to login while waiting for OTP
  otpRequired: boolean;
  otpVerified: boolean;
  otpCode: string | null;
  isOtpWrong: boolean;
  isOtpLimitReached: boolean;
  otpLoading: boolean;
  passwordChanged: boolean;
  passwordResetSuccess: boolean;
  selectedCompany: CompanyDbModel;
  was_saml: boolean;
}

const AuthInitialState: AuthState = {
  loading: false,
  logged: false,
  otpRequestLoading: false,
  user: {} as JwtUserPayload,
  companies: [],
  recoveryDone: false,
  recoveryEmail: null,
  loginEmail: null,
  otpRequired: false,
  otpVerified: false,
  otpCode: null,
  otpLoading: false,
  isOtpWrong: false,
  isOtpLimitReached: false,
  passwordChanged: false,
  passwordResetSuccess: false,
  selectedCompany: {} as CompanyDbModel,
  was_saml: false,
};

const slice = createSlice({
  name: AUTH_SLICE,
  initialState: AuthInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(LOGIN.pending, (state, _) => ({
        ...state,
        loading: true,
        was_saml: false,
      }))
      .addCase(
        LOGIN.fulfilled,
        (
          state,
          action: PayloadAction<CustomJwtUserPayload | { otp_required: boolean; email: string }>
        ) => {
          if ('otp_required' in action.payload) {
            return {
              ...state,
              loading: false,
              otpRequired: action.payload.otp_required,
              loginEmail: action.payload.email,
            };
          }
          const { companies, ...user } = action.payload;
          return {
            ...state,
            loading: false,
            logged: true,
            user,
            companies,
            selectedCompany: companies[0],
          };
        }
      )
      .addCase(LOGIN.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(SAML_RESUME.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(SAML_RESUME.fulfilled, (state, action: PayloadAction<CustomJwtUserPayload>) => ({
        ...state,
        loading: false,
        logged: true,
        user: {
          ...action.payload,
        },
        selectedCompany: action.payload.companies[0],
      }))
      .addCase(SAML_RESUME.rejected, (state) => ({
        ...state,
        loading: false,
      }))
      .addCase(LOGOUT.fulfilled, (_, __) => ({
        ...AuthInitialState,
        was_saml: true,
      }))
      .addCase(RECOVERY.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(RECOVERY.fulfilled, (state, action: PayloadAction<string>) => ({
        ...state,
        loading: false,
        recoveryDone: true,
        recoveryEmail: action.payload,
      }))
      .addCase(RECOVERY.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(VERIFY.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(VERIFY.fulfilled, (state, action: PayloadAction<string>) => ({
        ...state,
        loading: false,
        otpVerified: true,
        otpCode: action.payload,
      }))
      .addCase(VERIFY.rejected, (state, action) => {
        // Reject code for OTP: 027 - Wrong OTP; 083 - Hit OTP limit
        const errorCode = action.payload;
        return {
          ...state,
          loading: false,
          isOtpWrong: errorCode === '027',
          isOtpLimitReached: errorCode === '083',
        };
      })
      .addCase(RESET_PASSWORD.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(RESET_PASSWORD.fulfilled, (state, _) => ({
        ...state,
        loading: false,
        passwordResetSuccess: true,
      }))
      .addCase(RESET_PASSWORD.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(LOGIN_OTP_VERIFY.pending, (state, _) => ({
        ...state,
        otpLoading: true,
      }))
      .addCase(LOGIN_OTP_VERIFY.fulfilled, (state, action) => {
        if (action.payload) {
          const { companies, ...user } = action.payload;
          return {
            ...state,
            loading: false,
            logged: true,
            isOtpWrong: false,
            otpLoading: false,
            otpRequired: false,
            user,
            companies,
            selectedCompany: companies[0],
          };
        } else {
          // Reset user login
          return {
            ...state,
            recoveryEmail: null,
            recoveryDone: false,
            otpCode: null,
            otpVerified: false,
            otpRequired: false,
            passwordChanged: false,
            isOtpWrong: false,
            isOtpLimitReached: false,
          };
        }
      })
      .addCase(LOGIN_OTP_VERIFY.rejected, (state, action) => {
        // Reject code for OTP: 027 - Wrong OTP; 083 - Hit OTP limit
        const errorCode = action.payload;
        return {
          ...state,
          loading: false,
          isOtpWrong: errorCode === '027',
          isOtpLimitReached: errorCode === '083',
          otpLoading: false,
        };
      })
      .addCase(STORE_USER.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(STORE_USER.fulfilled, (state, action: PayloadAction<CustomJwtUserPayload>) => {
        const { companies, ...user } = action.payload;
        return {
          ...state,
          recoveryDone: false,
          recoveryEmail: null,
          otpVerified: false,
          otpCode: null,
          passwordChanged: false,
          logged: true,
          loading: false,
          user,
          companies,
        };
      })
      .addCase(STORE_USER.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(resetUser, (state, _action) => ({
        ...state,
        recoveryEmail: null,
        recoveryDone: false,
        otpCode: null,
        otpVerified: false,
        otpRequired: false,
        passwordChanged: false,
        isOtpWrong: false,
        isOtpLimitReached: false,
      }))
      .addCase(changeCompany, (state, action: PayloadAction<number>) => ({
        ...state,
        selectedCompany: state.companies.find(
          (company: CompanyDbModel) => company.id === action.payload
        ) as CompanyDbModel,
      }))
      .addCase(CHANGE_AVATAR.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(CHANGE_AVATAR.fulfilled, (state, action: PayloadAction<JwtUserPayload>) => ({
        ...state,
        loading: false,
        user: {
          ...action.payload,
        },
      }))
      .addCase(CHANGE_AVATAR.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(CHANGE_ACCOUNT_INFO.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(CHANGE_ACCOUNT_INFO.fulfilled, (state, action: PayloadAction<JwtUserPayload>) => ({
        ...state,
        loading: false,
        user: {
          ...action.payload,
        },
      }))
      .addCase(CHANGE_ACCOUNT_INFO.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(REQUEST_CHANGE_PASSWORD_OTP_CODE.pending, (state, _) => ({
        ...state,
        otpRequestLoading: true,
      }))
      .addCase(REQUEST_CHANGE_PASSWORD_OTP_CODE.fulfilled, (state, _) => ({
        ...state,
        otpRequestLoading: false,
        otpRequired: true,
        isOtpWrong: false,
      }))
      .addCase(REQUEST_CHANGE_PASSWORD_OTP_CODE.rejected, (state, _) => ({
        ...state,
        otpRequestLoading: false,
      }))
      .addCase(VERIFY_CHANGE_PASSWORD_OTP_CODE.pending, (state, _) => ({
        ...state,
        otpRequestLoading: true,
        otpVerified: false,
      }))
      .addCase(
        VERIFY_CHANGE_PASSWORD_OTP_CODE.fulfilled,
        (state, action: PayloadAction<string>) => ({
          ...state,
          otpRequestLoading: false,
          otpRequired: false,
          otpVerified: true,
          isOtpWrong: false,
          otpCode: action.payload,
        })
      )
      .addCase(VERIFY_CHANGE_PASSWORD_OTP_CODE.rejected, (state, action) => {
        const errorCode = action.payload;
        return {
          ...state,
          otpRequestLoading: false,
          isOtpWrong: errorCode === '027',
          isOtpLimitReached: errorCode === '083',
        };
      })
      .addCase(SET_NEW_PASSWORD.pending, (state, _) => ({
        ...state,
        otpRequestLoading: true,
        otpVerified: false,
      }))
      .addCase(SET_NEW_PASSWORD.fulfilled, (state, _) => ({
        ...state,
        otpRequestLoading: false,
        otpVerified: false,
        otpCode: null,
        passwordChanged: true,
      }))
      .addCase(SET_NEW_PASSWORD.rejected, (state, _) => ({
        ...state,
        otpRequestLoading: false,
        otpVerified: false,
        otpCode: null,
        passwordChanged: false,
      }))
      .addCase(CHANGE_COMPANY_DETAILS.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        CHANGE_COMPANY_DETAILS.fulfilled,
        (state, action: PayloadAction<CompanyDbModel[]>) => ({
          ...state,
          loading: false,
          companies: action.payload,
          selectedCompany: action.payload.find(
            (company: CompanyDbModel) => company.id === state.selectedCompany!.id
          ) as CompanyDbModel,
        })
      )
      .addCase(CHANGE_COMPANY_DETAILS.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(CHANGE_TEMPORARY_PASSWORD.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        CHANGE_TEMPORARY_PASSWORD.fulfilled,
        (state, action: PayloadAction<JwtUserPayload>) => ({
          ...state,
          loading: false,
          user: {
            ...action.payload,
          },
        })
      )
      .addCase(CHANGE_TEMPORARY_PASSWORD.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(UPLOAD_CUSTOM_PRIVACY_POLICY.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        UPLOAD_CUSTOM_PRIVACY_POLICY.fulfilled,
        (state, action: PayloadAction<CompanyDbModel[]>) => ({
          ...state,
          loading: false,
          selectedCompany: action.payload.find(
            (company: CompanyDbModel) => company.id === state.selectedCompany!.id
          ) as CompanyDbModel,
        })
      )
      .addCase(UPLOAD_CUSTOM_PRIVACY_POLICY.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(DELETE_CUSTOM_PRIVACY_POLICY.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        DELETE_CUSTOM_PRIVACY_POLICY.fulfilled,
        (state, action: PayloadAction<CompanyDbModel[]>) => ({
          ...state,
          loading: false,
          selectedCompany: action.payload.find(
            (company: CompanyDbModel) => company.id === state.selectedCompany!.id
          ) as CompanyDbModel,
        })
      )
      .addCase(DELETE_CUSTOM_PRIVACY_POLICY.rejected, (state, _) => ({
        ...state,
        loading: false,
      })),
});

export default slice.reducer;
