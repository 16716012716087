import { useEffect } from 'react';

import { removeErrorMessage, removeSuccessMessage } from '../store/popup/actions';
import { getErrorMessage, getSuccessMessage } from '../store/popup/selectors';
import { useAppDispatch, useAppSelector } from '../store/store';
const { VITE_POPUP_MESSAGE_TIMEOUT } = import.meta.env;

interface Properties {
  error: { message: string; [key: string]: unknown } | null;
  success: { message: string; [key: string]: unknown } | null;
}

const usePopupMessage = (): Properties => {
  const dispatch = useAppDispatch();

  const error = useAppSelector(getErrorMessage);
  const success = useAppSelector(getSuccessMessage);

  useEffect(() => {
    const timeout: ReturnType<typeof setTimeout> = setTimeout(
      () => {
        if (error) return dispatch(removeErrorMessage());
        if (success) return dispatch(removeSuccessMessage());
      },
      VITE_POPUP_MESSAGE_TIMEOUT ? +VITE_POPUP_MESSAGE_TIMEOUT : 3000
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [error, success, dispatch]);

  return { error, success };
};

export default usePopupMessage;
