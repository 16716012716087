export type Theme = typeof theme;

const theme = {
  shadow: {
    /*
    Use this design token for layer. Most often used for buttons, input, select and more.
    */
    100: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    /*
    Use this design token for layer. Most often used for surface. Most often used for form card.
    */
    200: '0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.06);',
    /*
    Use this design token for layer. Most often used for dialog, dropdown and more.
    */
    300: '0px 12px 12px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);',
  },

  transition: {
    /*
    Use this design token for a quick transition. Most often used for quick hover effects on things such as links, icons and more.
    */
    quickly: '0.05s ease',

    /*
    Use this design token for a slow transition. Used for example in modals, tooltips, collapsing elements, and more.
    */
    slowly: '0.2s ease',
    /*
    Use this design token for a pop transition. Used for example in dropdown.
    */
    pop: '0.4s ease',
  },

  output: {
    // Use this design token for layer/background.
    extraLight: {
      100: '#FFF1EC',
      200: '#FFF8E0',
      300: '#FBFFE0',
      400: '#EAFFD7',
      500: '#DFFFEA',
    },
    // Use this design token for layer border.
    light: {
      100: '#F8DFD7',
      200: '#F7E9BB',
      300: '#E8F1B1',
      400: '#D4F1B8',
      500: '#B8F1CB',
    },
    // Use this design token for background.
    normal: {
      100: '#FFAD94',
      200: '#F9D866',
      300: '#D6ED49',
      400: '#92EF59',
      500: '#40F07C',
    },
    // Use this design token for text.
    dark: {
      100: '#8A2100',
      200: '#4D3700',
      300: '#4B5700',
      400: '#306000',
      500: '#00662F',
    },
  },

  // Likert background and text colors.
  likert: {
    light: {
      100: '#F8DFD7',
      200: '#F7E9BB',
      300: '#E8F1B1',
      400: '#D4F1B8',
      500: '#B8F1CB',
    },
    dark: {
      100: '#8A2100',
      200: '#4D3700',
      300: '#4B5700',
      400: '#306000',
      500: '#00662F',
    },
  },

  // Use this design token for breakpoint
  breakpoint: {
    x: 'var(--xs)',
    sm: 'var(--sm)',
    md: 'var(--md)',
    lg: 'var(--lg)',
    xl: 'var(--xl)',
    xxl: 'var(--xxl)',
  },

  // Use this design token for border-radius.
  radius: {
    small: '0.25rem',
    default: '0.5rem',
    medium: '0.75rem',
    large: '1rem',
    xlarge: '1.25rem',
    xxlarge: '1.5rem',
    max: '62.438rem',
  },

  // Use this design token for spacer.Used for example in padding and margin.
  spacer: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    8: '0.5rem',
    12: '0.75rem',
    14: '0.875rem',
    15: '0.9375rem',
    16: '1rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    96: '6rem',
    128: '8rem',
  },

  // Use this design token for spacer.Used for example in font-size and line-height.
  size: {
    6: '0.375rem',
    8: '0.5rem',
    12: '0.75rem',
    14: '0.875rem',
    15: '0.9375rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    22: '1.375rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    96: '6rem',
    128: '8rem',
  },

  lightUI: {
    name: 'light',
    text: {
      default: 'var(--txt-default)',
      muted: 'var(--txt-muted)',
      emphasis: 'var(--txt-emphasis)',
      // Use this design token for semantic text.
      success: 'var(--txt-success)',
      warning: 'var(--txt-warning)',
      danger: 'var(--txt-danger)',
      info: 'var(--txt-info)',
      tutorial: 'var(--txt-tutorial)',
      white: 'var(--txt-white)',
    },
    layer: {
      body: 'var(--bg-body)',
      background: 'var(--bg-background)',
      surface: 'var(--bg-surface)',
      border: 'var(--bg-border-100)',
      border200: '#a6a6a6',
      tutorial: 'var(--bg-tutorial)',
      emphasis: 'var(--bg-emphasis)',
      success: 'var(--bg-success)',
      // Use this design token for semantic layer/background.
      successOpacity: 'var(--bg-success-o)',
      warning: 'var(--bg-warning)',
      warningOpacity: 'var(--bg-warning-o)',
      danger: 'var(--bg-danger)',
      dangerOpacity: 'var(--bg-danger-o)',
      info: 'var(--bg-info)',
      infoOpacity: 'var(--bg-info-o)',
      infoOpacity100: 'var(--bg-info-100)',
    },
    brand: {
      primary: 'var(--bg-brand)',
      primary100: 'var(--bg-brand-100)',
      primaryOpacity: '#4B4DF7',
      primaryGradient: 'linear-gradient(rgb(137, 110, 253) 0%, rgb(79, 40, 248) 100%)',
    },
    // Coming soon
    enterpriseCustomBrand: {
      text: '#8889A0',
      background: '#8889A0',
    },
    marketing: {
      emphasize: 'linear-gradient(99.37deg, #6B69FE 1.83%, #FF5A50 53.2%, #FF9F46 96.7%);',
      emphasizeOpacity: 'linear-gradient(60.19deg, #C0BFFF 2.72%, #FFC5C1 54.78%, #FFD9B6 95.57%);',
    },
  },

  // Old colors
  popupMessage: {
    success: {
      backgroundColor: '#06D2A1',
      color: '#FFFFFF',
      boxShadow: '0 8px 10px rgba(8, 212, 163, 0.3)',
      border: '1px solid #04D4C7',
    },
    syncRequired: {
      backgroundColor: 'rgba(242, 92, 39, 0.8)',
      color: '#FFFFFF',
      boxShadow: '0 8px 10px rgba(240, 96, 46, 0.3)',
      border: '1px solid #F25C27',
    },
    error: {
      backgroundColor: 'rgba(232, 53, 53, 0.8)',
      color: '#FFFFFF',
      boxShadow: '0 8px 10px rgba(240, 27, 27, 0.3)',
      border: '1px solid #e21717',
    },
    info: {
      backgroundColor: '#04D4C7',
      color: '#FFFFFF',
      boxShadow: '0 8px 10px rgba(4, 212, 199, 0.3)',
      border: '1px solid #0499D4',
    },
  },
  i: {
    backgroundColor: '#FFFFFF',
    icons: {
      color: '#656565',
    },
    text: {
      color: '#656565',
    },
  },
  buttons: {
    primary: {
      backgroundColor: '#4344dc',
    },
    secondary: {
      border: '1px solid #4344dc',
      backgroundColor: '#FFFFFF',
    },
    disabled: {
      backgroundColor: '#3F3A7314',
      color: '#BBC4D4',
    },
  },
};

export default theme;
