import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SoftSkill } from '../../models/ThunksInterfaces/retrieveAvailableSkills';
import { JobAreaModel } from '../../models/jobArea.model';
import { JobSubFamilyModel } from '../../models/jobSubFamily.model';
import { JOB_AREAS_SLICE, resetSuggestions } from './actions';
import {
  RETRIEVE_AVAILABLE_JOB_AREAS,
  RETRIEVE_AVAILABLE_JOB_SENIORITY,
  RETRIEVE_AVAILABLE_JOB_SUB_FAMILIES,
  RETRIEVE_SUGGESTED_SKILLS,
  Suggestion,
} from './thunk';

export interface JobAreasState {
  areas: JobAreaModel[];
  senioritySuggestions: Suggestion[];
  subFamilies: JobSubFamilyModel[];
  suggestions: SoftSkill[];
}

const JobAreasInitialState: JobAreasState = {
  areas: [],
  senioritySuggestions: [],
  subFamilies: [],
  suggestions: [],
};

const slice = createSlice({
  name: JOB_AREAS_SLICE,
  initialState: JobAreasInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(
        RETRIEVE_AVAILABLE_JOB_AREAS.fulfilled,
        (state, action: PayloadAction<JobAreaModel[]>) => ({
          ...state,
          areas: [...action.payload],
        })
      )
      .addCase(
        RETRIEVE_AVAILABLE_JOB_SUB_FAMILIES.fulfilled,
        (state, action: PayloadAction<JobSubFamilyModel[]>) => ({
          ...state,
          subFamilies: [...action.payload],
        })
      )
      .addCase(
        RETRIEVE_AVAILABLE_JOB_SENIORITY.fulfilled,
        (state, action: PayloadAction<Suggestion[]>) => ({
          ...state,
          senioritySuggestions: [...action.payload],
        })
      )
      .addCase(
        RETRIEVE_SUGGESTED_SKILLS.fulfilled,
        (state, action: PayloadAction<SoftSkill[]>) => ({
          ...state,
          suggestions: [...action.payload],
        })
      )
      .addCase(resetSuggestions, (state, _) => ({
        ...state,
        senioritySuggestions: [],
        suggestions: [],
      })),
});

export default slice.reducer;
