import { faEye, faEyeSlash, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, FocusEvent, FunctionComponent, useState } from 'react';
import styled from 'styled-components';

interface Props {
  name: string;
  type: 'text' | 'password' | 'email' | 'phone' | 'number' | 'date';
  autoComplete?: string;
  placeholder: string;
  maxLength?: number;
  value: string;
  disabled?: boolean;
  invalid?: boolean;
  error?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onReset?: (name: string) => void;
}

const Input: FunctionComponent<Props> = ({
  name,
  type,
  autoComplete,
  placeholder,
  maxLength,
  value,
  disabled,
  invalid,
  error,
  onChange,
  onBlur,
  onReset,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => setShowPassword((state) => !state);

  const resetHandler = () => (onReset ? onReset(name) : null);

  return (
    <>
      <Container>
        <CustomInput
          id={name}
          name={name}
          type={type !== 'password' ? type : !showPassword ? 'password' : 'text'}
          autoComplete={autoComplete}
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          disabled={disabled ? disabled : false}
          invalid={invalid ? invalid : false}
          onChange={onChange ? onChange : () => null}
          onBlur={onBlur ? onBlur : () => null}
        />
        {type === 'password' && (
          <ShowPassword onClick={togglePassword}>
            {showPassword && <FontAwesomeIcon icon={faEye} />}
            {!showPassword && <FontAwesomeIcon icon={faEyeSlash} />}
          </ShowPassword>
        )}
        {value && value.length >= 1 && onReset && typeof onReset === 'function' && (
          <ResetButton onClick={resetHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </ResetButton>
        )}
      </Container>
      {invalid && error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default Input;

const Container = styled.div`
  position: relative;
`;

const CustomInput = styled.input<{ invalid: boolean; }>`
  padding: ${({ theme }) => theme.spacer[8]} ${({ theme }) => theme.spacer[12]};
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.radius.default};
  border: 1px solid
    ${({ invalid, theme }) => (invalid ? theme.lightUI.layer.danger : theme.lightUI.layer.border)};
  background-color: ${({ theme }) => theme.lightUI.layer.body};
  outline: none;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.lightUI.text.default};
  line-height: 20px;
  text-overflow: ellipsis;
  box-shadow: ${({ theme }) => theme.shadow[100]};

  &:focus {
    box-shadow: ${({ theme }) => theme.shadow[100]};
    border: 1px solid
      ${({ invalid, theme }) =>
    invalid ? theme.lightUI.layer.danger : theme.lightUI.layer.emphasis};
    background-color: ${({ theme }) => theme.lightUI.layer.body};
  }

  &:disabled {
    color: ${({ theme }) => theme.lightUI.text.muted};
    background-color: ${({ theme }) => theme.lightUI.layer.surface};
    cursor: not-allowed;
    opacity: 0.7;
  }

  &::placeholder {
    color: ${({ theme }) => theme.lightUI.text.muted};
    font-weight: 400;
    font-size: ${({ theme }) => theme.size[15]};
  }
`;

const ShowPassword = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;
  color: ${({ theme }) => theme.lightUI.text.muted};
  z-index: 100;
  cursor: pointer;
`;

const ResetButton = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.lightUI.text.muted};
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.lightUI.text.muted};
  }
`;

const ErrorMessage = styled.div`
  margin-top: 0.25rem;
  //min-height: 18px;
  font-size: ${({ theme }) => theme.size[14]};
  color: ${({ theme }) => theme.lightUI.text.danger};
`;
