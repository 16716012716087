import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface Props extends HTMLAttributes<HTMLDivElement> {
  size?: number;
  position?: 'static' | 'relative' | 'absolute';
  text?: string;
}

const Loader: FunctionComponent<Props> = ({ size, position, text, ...props }) => {
  return (
    <Container {...props}>
      <LoaderContainer
        size={size}
        position={position}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            margin: 'auto',
            background: 'transparent',
            display: 'block',
            shapeRendering: 'auto',
          }}
          width={`${size}px`}
          height={`${size}px`}
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            r="0"
            fill="none"
            stroke="#4e50fb"
            strokeWidth="2"
          >
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="2.5s"
              values="0;50"
              keyTimes="0;1"
              keySplines="0 0.2 0.8 1"
              calcMode="spline"
              begin="0s"
            />
            <animate
              attributeName="opacity"
              repeatCount="indefinite"
              dur="2.5s"
              values="1;0"
              keyTimes="0;1"
              keySplines="0.2 0 0.8 1"
              calcMode="spline"
              begin="0s"
            />
          </circle>
          <circle
            cx="50"
            cy="50"
            r="0"
            fill="none"
            stroke="#bccffa"
            strokeWidth="2"
          >
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="2.5s"
              values="0;50"
              keyTimes="0;1"
              keySplines="0 0.2 0.8 1"
              calcMode="spline"
              begin="-1.25s"
            />
            <animate
              attributeName="opacity"
              repeatCount="indefinite"
              dur="2.5s"
              values="1;0"
              keyTimes="0;1"
              keySplines="0.2 0 0.8 1"
              calcMode="spline"
              begin="-1.25s"
            />
          </circle>
        </svg>
      </LoaderContainer>
      {text !== undefined && <Text>{text}</Text>}
    </Container>
  );
};

export default Loader;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoaderContainer = styled.div<Partial<Props>>`
  position: ${({ position }) => position};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin-bottom: 10px;
`;

const Text = styled.span`
  font-size: 0.85em;
  color: #8e96a6;
`;
