import styled from 'styled-components';

interface Props {
  visible: boolean;
}

const BlockingOverlay = ({ visible = false }: Props) => {
  return <Container visible={visible} />;
};

export default BlockingOverlay;

const Container = styled.div<{ visible: boolean }>`
  position: fixed;
  z-index: ${(props) => (props.visible ? 101 : -1)};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.visible ? 'rgba(0, 0, 0, .4)' : 'transparent')};
  transition: background-color 0.2s ease-in-out;
`;
