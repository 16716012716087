import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  msg: { message: string; [key: string]: unknown } | null;
  type: 'success' | 'error' | 'info';
  show: boolean;
}

const variants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.5,
    },
  },
};

const PopupMessage = ({ msg, type, show }: Props) => {
  const { t } = useTranslation(['popups']);

  if (type && type === 'success') {
    return (
      <SuccessContainer
        variants={variants}
        initial="hidden"
        animate={show ? 'show' : 'hidden'}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 12.5L11 15.5L16 9.5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            stroke="white"
            strokeWidth="2"
          />
        </svg>
        {msg && <Text>{t(msg.message, { ...msg })}</Text>}
      </SuccessContainer>
    );
  }

  if (type && type === 'error') {
    return (
      <ErrorContainer
        variants={variants}
        initial="hidden"
        animate={show ? 'show' : 'hidden'}
      >
        <IconContainer>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="white"
              strokeWidth="2"
            />
            <path
              d="M12 7V13M12 16.5V17"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </IconContainer>
        {msg && <Text>{t(msg.message, { ...msg })}</Text>}
      </ErrorContainer>
    );
  }

  return (
    <DefaultContainer
      variants={variants}
      initial="hidden"
      animate={show ? 'show' : 'hidden'}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 12.5L11 15.5L16 9.5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
      {msg && <Text>{t(msg.message, { ...msg })}</Text>}
    </DefaultContainer>
  );
};

export default PopupMessage;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DefaultContainer = styled(motion.div)`
  pointer-events: none;
  position: fixed;
  top: 25px;
  right: 25px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  z-index: 200;
  background-color: ${({ theme }) => theme.lightUI.layer.info};
  border: ${({ theme }) => theme.lightUI.layer.info};
  color: ${({ theme }) => theme.lightUI.text.emphasis};
  border-radius: ${({ theme }) => theme.radius.default};
`;

const SuccessContainer = styled(motion.div)`
  pointer-events: none;
  position: fixed;
  top: 25px;
  right: 25px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  z-index: 200;

  background-color: ${({ theme }) => theme.lightUI.layer.success};
  border: ${({ theme }) => theme.lightUI.layer.success};
  color: ${({ theme }) => theme.lightUI.text.emphasis};
  border-radius: ${({ theme }) => theme.radius.default};
`;

const ErrorContainer = styled(motion.div)`
  pointer-events: none;
  position: fixed;
  top: 25px;
  right: 25px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  z-index: 200;

  background-color: ${({ theme }) => theme.lightUI.layer.danger};
  border: ${({ theme }) => theme.lightUI.layer.danger};
  color: ${({ theme }) => theme.lightUI.text.emphasis};
  border-radius: ${({ theme }) => theme.radius.default};
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 1rem;
  margin: 0 0 0 10px;
`;
