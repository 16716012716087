export const EMAIL_REGEX: RegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const PASSWORD_REGEX: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!$£%^&#@°*()_+|~=`{§}\[\]:";'<>?,.\/-])[0-9a-zA-Z!$£%^&#@°*()_+|~=`{§}\[\]:";'<>?,.\/-]{12,}$/;

/** At least a numer */
export const STRING_HAS_NUMBER_REGEX: RegExp = /(?=.*\d)/;

/** At least a lowercase letter */
export const STRING_HAS_LOWERCASE_REGEX: RegExp = /(?=.*[a-z])/;

/** At least an uppercase letter */
export const STRING_HAS_UPPERCASE_REGEX: RegExp = /(?=.*[A-Z])/;

/** At least a special char */
export const STRING_HAS_SPECIAL_CHAR_REGEX: RegExp = /(?=.*[!$£%^&#@°*()_+|~=`{§}\[\]:";'<>?,.\/-])/;

/** At least 12 chars */
export const STRING_HAS_MIN_LENGTH_REGEX: RegExp = /.{12,}/;



export const PHONE_REGEX: RegExp = /^\+[1-9]\d{6,14}$/; // 7 digits seems to be the minimum

export const VIDEO_FORMATS_REGEX: RegExp = /mp4|webm|mkv|flv|vob|ogm|wmv|ogg|mov|mpg|mpeg|avi/;

export const SMS_SENDER_REGEX: RegExp = /^[a-zA-Z0-9]*$/;
