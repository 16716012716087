import { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  opened: boolean;
  maxWidth?: number;
  padding?: string;
  backgroundColor?: string;
  children?: ReactNode;
}

const Modal: FunctionComponent<Props> = ({
  opened,
  maxWidth,
  padding,
  backgroundColor,
  children,
}) => {
  return (
    <Container
      opened={opened}
      backgroundColor={backgroundColor}
      maxWidth={maxWidth}
      padding={padding}
    >
      {children}
    </Container>
  );
};

export default Modal;

const Container = styled.div<{
  opened: boolean;
  backgroundColor?: string;
  maxWidth?: number;
  padding?: string;
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  ${(props) => (props.maxWidth ? `width: 100%` : null)};
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth}px` : null)};
  z-index: ${(props) => (props.opened ? 101 : -1)};
  opacity: ${(props) => (props.opened ? 1 : 0)};
  transform: translate(-50%, ${(props) => (props.opened ? '-50%' : '-45%')});
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#F6F6F8')};
  padding: ${({ padding, theme }) => (padding ? padding : theme.spacer[16])};
  border-radius: ${({ theme }) => theme.radius.medium};
  overflow: hidden;
  transition:
    transform 0.2s ease-in-out 0.2s,
    opacity 0.2s ease-in-out 0.2s;
  box-shadow: ${({ theme }) => theme.shadow[300]};
`;
