import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { BACKEND_ENDPOINTS } from '../../constants/endpoints';
import { setErrorMessage, setSuccessMessage } from '../../store/popup/actions';
import { downloadFile, http } from '../../utils/http';
import { ApplicationsEnum } from './enums/actions';
import { ApplicationStatusDbModel } from './interfaces/applicationStatusDb.model';
import { DeleteApplicationRating } from './interfaces/deleteApplicationRating';
import { DeleteApplicationState } from './interfaces/deleteApplicationState';
import { InviteCandidateToVideoInterview } from './interfaces/inviteCandidateToVideoInteview';
import { RetrieveApplication } from './interfaces/retrieveApplication';
import { RetrieveApplications } from './interfaces/retrieveApplications';
import { RetrieveJobPostStates } from './interfaces/retrieveJobPostStates';
import { SubmitApplicationRating } from './interfaces/submitApplicationRating';
import { SubmitApplicationState } from './interfaces/submitApplicationState';
import { SubmitCandidateReportRequest } from './interfaces/submitCandidateReportRequest';
import { SubmitCvRating } from './interfaces/submitCvRating';
import { SubmitCvUpload } from './interfaces/submitCvUpload';
import {
  SubmitEmailFeedbackRequest,
  SubmitEmailFeedbackResponse,
} from './interfaces/submitEmailFeedback';
import {
  SubmitPresentationRatingRequest,
  SubmitPresentationRatingResponse,
} from './interfaces/submitPresentationRating';
import { UpdateApplicationState } from './interfaces/updateApplicationState';
import { UpdateApplicationStatus } from './interfaces/updateApplicationStatus';

export const RETRIEVE_JOBPOST_APPLICATIONS = createAsyncThunk(
  ApplicationsEnum.RETRIEVE_JOBPOST_APPLICATIONS,
  async ({
    jobPostId,
    status,
    orderBy,
    text,
  }: RetrieveApplications.Request): Promise<RetrieveApplications.Response> => {
    let data: RetrieveApplications.Response;
    try {
      const options: AxiosRequestConfig = {
        method: 'GET',
        params: {
          status,
          orderBy,
        },
      };
      if (text && text.trim().length > 0) {
        options.params = { ...options.params, text };
      }
      const response = await http(
        `${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/applications`,
        options
      );
      data = response.data.data;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);

export const RETRIEVE_APPLICATION = createAsyncThunk(
  ApplicationsEnum.RETRIEVE_APPLICATION,
  async ({
    jobPostId,
    applicationId,
  }: RetrieveApplication.Request): Promise<RetrieveApplication.Response> => {
    let data: RetrieveApplication.Response;
    try {
      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}`, {
        method: 'GET',
      });
      data = response.data.data;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);

export const UPDATE_APPLICATION_STATUS = createAsyncThunk(
  ApplicationsEnum.UPDATE_APPLICATION_STATUS,
  async (
    { jobPostId, applicationId, status, cv }: UpdateApplicationStatus.Request,
    thunkAPI
  ): Promise<RetrieveApplication.Response> => {
    let data: RetrieveApplication.Response;
    try {
      const formData = new FormData();
      if (status) {
        formData.append('status', status.toString());
      }
      if (cv) {
        formData.append('cv', cv);
      }
      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      data = { ...response.data.data, application_id: applicationId };
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'applications.stages.move.reject' }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'applications.stages.move.confirm' }));
    return data;
  }
);

export const UPDATE_APPLICATION = createAsyncThunk(
  ApplicationsEnum.UPDATE_APPLICATION,
  async (
    {
      jobPostId,
      applicationId,
      name,
      surname,
      email,
      phone,
      emailInvite,
      smsInvite,
    }: {
      jobPostId: string;
      applicationId: string;
      name: string;
      surname: string;
      email: string;
      phone: string;
      emailInvite: boolean;
      smsInvite: boolean;
    },
    thunkAPI
  ): Promise<RetrieveApplication.Response> => {
    let data: RetrieveApplication.Response;
    let response;
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('surname', surname);
      formData.append('email', email);
      formData.append('phone', phone.replace(/\+/, ''));
      formData.append('sendEmailNotification', `${emailInvite}`);
      formData.append('sendSmsNotification', `${smsInvite}`);
      response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      data = response.data.data;
    } catch (e: any) {
      let message: string;
      if (e.response.data.error.message === 'email-already-exist-on-job-post') {
        message = 'application.update.email.duplicate';
      } else {
        message = 'application.update.reject';
      }
      thunkAPI.dispatch(setErrorMessage({ message }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'application.update.confirm' }));
    return data;
  }
);

export const DELETE_APPLICATION = createAsyncThunk(
  ApplicationsEnum.DELETE_APPLICATION,
  async (
    { jobPostId, applicationId }: { jobPostId: number; applicationId: number },
    thunkAPI
  ): Promise<number> => {
    try {
      await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}`, {
        method: 'DELETE',
      });
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'application.delete.reject' }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'application.delete.confirm' }));
    return applicationId;
  }
);

export const SUBMIT_APPLICATION_RATING = createAsyncThunk(
  ApplicationsEnum.SUBMIT_APPLICATION_RATING,
  async (
    { jobPostId, applicationId, questionId, rating }: SubmitApplicationRating.Request,
    thunkAPI
  ): Promise<SubmitApplicationRating.Response> => {
    let data: SubmitApplicationRating.Response;
    try {
      const response = await http(
        `${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}/rating`,
        {
          method: 'POST',
          data: {
            questionId,
            rating,
          },
        }
      );
      data = {
        application: { ...response.data.data },
        questionId,
      };
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'applications.submitRating.reject' }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'applications.submitRating.confirm' }));
    return data;
  }
);

export const DELETE_APPLICATION_RATING = createAsyncThunk(
  ApplicationsEnum.DELETE_APPLICATION_RATING,
  async (
    { jobPostId, applicationId, questionId }: DeleteApplicationRating.Request,
    thunkAPI
  ): Promise<DeleteApplicationRating.Response> => {
    let data: SubmitApplicationRating.Response;
    try {
      const response = await http(
        `${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}/hr-vote`,
        {
          method: 'DELETE',
          data: {
            questionId,
          },
        }
      );
      data = {
        application: { ...response.data.data },
        questionId,
      };
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'applications.submitRating.reject' }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'applications.submitRating.confirm' }));
    return data;
  }
);

export const RETRIEVE_JOB_POST_STATES = createAsyncThunk(
  ApplicationsEnum.RETRIEVE_JOB_POST_STATES,
  async ({ jobPostId }: RetrieveJobPostStates.Request): Promise<RetrieveJobPostStates.Response> => {
    let data: RetrieveJobPostStates.Response;
    try {
      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/states`, {
        method: 'GET',
      });
      data = response.data.data;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);

export const SUBMIT_APPLICATION_STATE = createAsyncThunk(
  ApplicationsEnum.SUBMIT_APPLICATION_STATE,
  async (
    { jobPostId, name }: SubmitApplicationState.Request,
    thunkAPI
  ): Promise<ApplicationStatusDbModel> => {
    let data: ApplicationStatusDbModel;
    try {
      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/stages`, {
        method: 'POST',
        data: {
          name,
        },
      });
      data = response.data.data;
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'applications.stages.add.reject', name }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'applications.stages.add.confirm', name }));
    return data;
  }
);

export const UPDATE_APPLICATION_STATE = createAsyncThunk(
  ApplicationsEnum.UPDATE_APPLICATION_STATE,
  async (
    { jobPostId, stageId, name }: UpdateApplicationState.Request,
    thunkAPI
  ): Promise<ApplicationStatusDbModel> => {
    let data: ApplicationStatusDbModel;
    try {
      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/stages/${stageId}`, {
        method: 'PATCH',
        data: {
          name,
        },
      });
      data = response.data.data;
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'applications.stages.update.reject' }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'applications.stages.update.confirm', name }));
    return data;
  }
);

export const DELETE_APPLICATION_STATE = createAsyncThunk(
  ApplicationsEnum.DELETE_APPLICATION_STATE,
  async ({ jobPostId, stageId }: DeleteApplicationState.Request, thunkAPI): Promise<string> => {
    try {
      await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/stages/${stageId}`, {
        method: 'DELETE',
      });
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'applications.stages.delete.reject' }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'applications.stages.delete.confirm' }));
    return stageId;
  }
);

export const SINGLE_EMAIL_INVITE_CANDIDATE_TO_VIDEOINTERVIEW = createAsyncThunk(
  ApplicationsEnum.SINGLE_EMAIL_INVITE_CANDIDATE_TO_VIDEOINTERVIEW,
  async (
    { jobPostId, applicationId }: InviteCandidateToVideoInterview.Request,
    thunkAPI
  ): Promise<InviteCandidateToVideoInterview.Response> => {
    let response;
    try {
      response = await http(
        `${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}/single-email-invite`,
        {
          method: 'POST',
        }
      );
      if (response.data.success) {
        thunkAPI.dispatch(setSuccessMessage({ message: 'applications.inviteCandidate.confirm' }));
      } else {
        thunkAPI.dispatch(setErrorMessage({ message: 'applications.inviteCandidate.reject' }));
        throw Error("Si è verificato un errore durante l'invio dell'invito");
      }
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return { applicationId };
  }
);

export const BULK_EMAIL_INVITE_TO_VIDEOINTERVIEW = createAsyncThunk(
  ApplicationsEnum.BULK_EMAIL_INVITE_TO_VIDEOINTERVIEW,
  async ({ jobPostId, ids }: { jobPostId: number; ids: number[] }, thunkAPI): Promise<void> => {
    try {
      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/bulk-email-invite`, {
        method: 'POST',
        data: {
          ids,
        },
      });
      if (response.data.success) {
        thunkAPI.dispatch(setSuccessMessage({ message: 'applications.emailBulkInvite.confirm' }));
      } else {
        thunkAPI.dispatch(setErrorMessage({ message: 'applications.emailBulkInvite.reject' }));
        throw Error("Si è verificato un errore durante l'invio dell'invito");
      }
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return;
  }
);
export const BULK_SMS_INVITE_TO_VIDEOINTERVIEW = createAsyncThunk(
  ApplicationsEnum.BULK_SMS_INVITE_TO_VIDEOINTERVIEW,
  async (
    { jobPostId, ids }: { jobPostId: number; ids: number[] },
    thunkAPI
  ): Promise<{ successCounter: number; totalCounter: number }> => {
    try {
      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/bulk-sms-invite`, {
        method: 'POST',
        data: {
          ids,
        },
      });
      if (response.data.success) {
        const { successCounter, totalCounter }: { successCounter: number; totalCounter: number } =
          response.data.data;
        if (successCounter === totalCounter) {
          thunkAPI.dispatch(setSuccessMessage({ message: 'applications.phoneBulkInvite.confirm' }));
        }
        return {
          successCounter,
          totalCounter,
        };
      } else {
        thunkAPI.dispatch(setErrorMessage({ message: 'applications.phoneBulkInvite.reject' }));
        throw Error("Si è verificato un errore durante l'invio dell'invito");
      }
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
  }
);
export const BULK_APPLICATIONS_PHONE_UPDATE = createAsyncThunk(
  ApplicationsEnum.BULK_APPLICATIONS_PHONE_UPDATE,
  async (
    {
      jobPostId,
      applications,
    }: {
      jobPostId: number;
      applications: {
        id: number;
        phone: string;
        checked: boolean;
        valid: boolean;
      }[];
    },
    thunkAPI
  ): Promise<void> => {
    try {
      await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/update-applications-phone`, {
        method: 'PATCH',
        data: {
          applications: applications.filter((application) => application.valid),
        },
      });
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'applications.bulkPhoneUpdate.reject' }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'applications.bulkPhoneUpdate.confirm' }));
    return;
  }
);
export const SINGLE_SMS_INVITE_CANDIDATE_TO_VIDEOINTERVIEW = createAsyncThunk(
  ApplicationsEnum.SINGLE_SMS_INVITE_CANDIDATE_TO_VIDEOINTERVIEW,
  async (
    { jobPostId, applicationId }: InviteCandidateToVideoInterview.Request,
    thunkAPI
  ): Promise<InviteCandidateToVideoInterview.Response> => {
    let response;
    try {
      response = await http(
        `${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}/single-sms-invite`,
        {
          method: 'POST',
        }
      );
      if (response.data.success) {
        thunkAPI.dispatch(setSuccessMessage({ message: 'applications.inviteCandidate.confirm' }));
      } else {
        thunkAPI.dispatch(setErrorMessage({ message: 'applications.inviteCandidate.reject' }));
        throw Error("Si è verificato un errore durante l'invio dell'invito");
      }
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return { applicationId };
  }
);
export const SUBMIT_CV_RATING = createAsyncThunk(
  ApplicationsEnum.SUBMIT_CV_RATING,
  async (
    { jobPostId, applicationId, rating }: SubmitCvRating.Request,
    thunkAPI
  ): Promise<SubmitCvRating.Response> => {
    try {
      const response = await http(
        `${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}/cv-rating`,
        {
          method: 'POST',
          data: {
            rating,
          },
        }
      );
      if (response.data.success) {
        thunkAPI.dispatch(setSuccessMessage({ message: 'applications.submitCvRating.confirm' }));
      } else {
        thunkAPI.dispatch(setErrorMessage({ message: 'applications.submitCvRating.reject' }));
      }
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return {
      applicationId,
      rating,
    };
  }
);

export const SUBMIT_CV_UPLOAD = createAsyncThunk(
  ApplicationsEnum.SUBMIT_CV_UPLOAD,
  async (
    { jobPostId, applicationId, cv }: SubmitCvUpload.Request,
    thunkAPI
  ): Promise<SubmitCvUpload.Response> => {
    let data: SubmitCvUpload.Response;
    try {
      const formData = new FormData();
      formData.append('cv', cv);
      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      data = response.data.data;
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'applications.uploadCv.reject' }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'applications.uploadCv.confirm' }));

    return {
      applicationId,
      cv: data.cv,
    };
  }
);

export const EXPORT_CANDIDATES_TO_EXCEL = createAsyncThunk(
  ApplicationsEnum.EXPORT_CANDIDATES_TO_EXCEL,
  async ({ jobPostId }: { jobPostId: number }, thunkAPI): Promise<void> => {
    let file: File | undefined;
    try {
      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/export-candidates`, {
        method: 'POST',
        responseType: 'blob',
      });
      if (response.status === 200) {
        file = new File([response.data], response.headers['algo-filename'], {
          type: response.headers['content-type'],
        });
      }
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'jobPost.candidates.download.reject' }));
      throw Error(e.response.data.error.message);
    }
    if (!file) {
      thunkAPI.dispatch(setErrorMessage({ message: 'jobPost.candidates.download.reject' }));
      return;
    }
    const link = window.document.createElement('a');
    const url: string = URL.createObjectURL(file);
    link.href = url;
    link.download = file.name;
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    thunkAPI.dispatch(setSuccessMessage({ message: 'jobPost.candidates.download.confirm' }));
    return;
  }
);

export const REQUEST_CANDIDATE_REPORT = createAsyncThunk(
  ApplicationsEnum.REQUEST_CANDIDATE_REPORT,
  async (
    { jobPostId, applicationId, requested_by, tier }: SubmitCandidateReportRequest.Request,
    thunkAPI
  ): Promise<RetrieveApplication.Reports> => {
    let data: RetrieveApplication.Reports;
    try {
      const response = await http(
        `${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}/report`,
        {
          method: 'POST',
          data: {
            requested_by,
            tier,
          },
        }
      );
      data = response.data.data.reports;
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'candidate.report.request.reject' }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage({ message: 'candidate.report.request.confirm' }));
    return data;
  }
);

export const DOWNLOAD_CANDIDATE_REPORT_LIGHT = createAsyncThunk(
  ApplicationsEnum.DOWNLOAD_CANDIDATE_REPORT_LIGHT,
  async (
    { pdf_url, mimetype, filename }: { pdf_url: string; mimetype: string; filename: string },
    thunkAPI
  ) => {
    await downloadFile(
      pdf_url,
      mimetype,
      filename,
      () => {
        thunkAPI.dispatch(setSuccessMessage({ message: 'candidate.report.download.confirm' }));
      },
      (e: any) => {
        thunkAPI.dispatch(setErrorMessage({ message: 'candidate.report.download.reject' }));
        throw e;
      }
    );
  }
);

export const DOWNLOAD_CANDIDATE_REPORT_PRO = createAsyncThunk(
  ApplicationsEnum.DOWNLOAD_CANDIDATE_REPORT_PRO,
  async (
    { pdf_url, mimetype, filename }: { pdf_url: string; mimetype: string; filename: string },
    thunkAPI
  ) => {
    await downloadFile(
      pdf_url,
      mimetype,
      filename,
      () => {
        thunkAPI.dispatch(setSuccessMessage({ message: 'candidate.report.download.confirm' }));
      },
      (e: any) => {
        thunkAPI.dispatch(setErrorMessage({ message: 'candidate.report.download.reject' }));
        throw e;
      }
    );
  }
);

export const SUBMIT_PRESENTATION_RATING = createAsyncThunk(
  ApplicationsEnum.SUBMIT_PRESENTATION_RATING,
  async (
    { jobPostId, applicationId, rating }: SubmitPresentationRatingRequest,
    thunkAPI
  ): Promise<SubmitPresentationRatingResponse> => {
    try {
      const response = await http(
        `${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}/presentation-rating`,
        {
          method: 'POST',
          data: {
            rating,
          },
        }
      );
      if (response.data.success) {
        thunkAPI.dispatch(
          setSuccessMessage({ message: 'applications.submitPresentationRating.confirm' })
        );
      } else {
        thunkAPI.dispatch(
          setErrorMessage({ message: 'applications.submitPresentationRating.reject' })
        );
      }
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return {
      applicationId,
      rating,
    };
  }
);

export const SUBMIT_EMAIL_FEEDBACK = createAsyncThunk(
  ApplicationsEnum.SUBMIT_EMAIL_FEEDBACK,
  async (
    { jobPostId, applicationId, draft, message, subject, type }: SubmitEmailFeedbackRequest,
    thunkAPI
  ): Promise<SubmitEmailFeedbackResponse | null> => {
    try {
      const response = await http(
        `${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}/${applicationId}/feedback`,
        {
          method: 'POST',
          data: {
            type,
            title: subject,
            content: message,
            draft,
          },
        }
      );
      if (response.data.success) {
        thunkAPI.dispatch(setSuccessMessage({ message: 'applications.submitFeedback.confirm' }));
        return {
          ...response.data.data.completeApplication.email_feedback,
          applicationId,
        } as SubmitEmailFeedbackResponse;
      } else {
        thunkAPI.dispatch(setErrorMessage({ message: 'applications.submitFeedback.reject' }));
      }
    } catch (e) {
      thunkAPI.dispatch(setErrorMessage({ message: 'applications.submitFeedback.reject' }));
      if (e instanceof AxiosError) {
        throw Error(e.response?.data.error.message);
      }
    }
    return null;
  }
);
