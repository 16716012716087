import { createAction } from '@reduxjs/toolkit';
import { ActionsEnum } from '../../enums/actions.enum';

export const setErrorMessage = createAction<{ message: string; [key: string]: unknown }>(
  ActionsEnum.SET_ERROR_MESSAGE
);
export const removeErrorMessage = createAction(ActionsEnum.REMOVE_ERROR_MESSAGE);
export const setSuccessMessage = createAction<{ message: string; [key: string]: unknown }>(
  ActionsEnum.SET_SUCCESS_MESSAGE
);
export const removeSuccessMessage = createAction(ActionsEnum.REMOVE_SUCCESS_MESSAGE);
