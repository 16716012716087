import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  Counters,
  Job,
  RetrieveJobPostData,
  UpdateJobPostStatusResponse,
} from '../../models/ThunksInterfaces/retrieveJobPosts';
import { resetJobPostList } from './actions';
import { RETRIEVE_JOB_POSTS, UPDATE_JOB_POST_STATUS } from './thunk';

export interface JobPostsState {
  loading: boolean;
  jobs: Job[];
  jobCounters: Counters;
}

const JobPostsInitialState: JobPostsState = {
  loading: false,
  jobs: [],
  jobCounters: { active: 0, archived: 0, search_results: 0 },
};

const JOB_POSTS_SLICE = 'JobPosts';

const slice = createSlice({
  name: JOB_POSTS_SLICE,
  initialState: JobPostsInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(resetJobPostList, (state, _) => ({
        ...state,
        jobs: [],
      }))
      .addCase(RETRIEVE_JOB_POSTS.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        RETRIEVE_JOB_POSTS.fulfilled,
        (state, action: PayloadAction<RetrieveJobPostData>) => {
          const newJobs = action.payload.jobs.filter((newJob) => {
            // Checks whether the new job ID is already in the existing list
            return !state.jobs.some((existingJob) => existingJob.id === newJob.id);
          });

          return {
            ...state,
            loading: false,
            jobs: [...state.jobs, ...newJobs], // Add only the new jobs
            jobCounters: action.payload.counters,
          };
        }
      )
      .addCase(RETRIEVE_JOB_POSTS.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(UPDATE_JOB_POST_STATUS.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        UPDATE_JOB_POST_STATUS.fulfilled,
        (state, action: PayloadAction<UpdateJobPostStatusResponse>) => {
          const jobCounters: Counters = { ...state.jobCounters };
          for (const key in jobCounters) {
            if (key === action.payload.status) {
              jobCounters[key as 'active' | 'archived'] =
                jobCounters[key as 'active' | 'archived'] + 1;
            } else {
              jobCounters[key as 'active' | 'archived'] =
                jobCounters[key as 'active' | 'archived'] - 1;
            }
          }
          return {
            ...state,
            jobCounters,
          };
        }
      )
      .addCase(UPDATE_JOB_POST_STATUS.rejected, (state, _) => ({
        ...state,
        loading: false,
      })),
});

export default slice.reducer;
