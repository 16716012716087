import { createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_ENDPOINTS } from '../../constants/endpoints';
import { ActionsEnum } from '../../enums/actions.enum';
import { LanguageDbModel } from '../../models/languageDb.model';
import { http } from '../../utils/http';

export const RETRIEVE_AVAILABLE_LANGUAGES = createAsyncThunk(
  ActionsEnum.RETRIEVE_AVAILABLE_LANGUAGES,
  async (): Promise<LanguageDbModel[]> => {
    let data: LanguageDbModel[];
    try {
      const response = await http(BACKEND_ENDPOINTS.LANGS, {
        method: 'GET',
      });
      data = response.data.data.languages;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);
