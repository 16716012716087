export const Routes = {
  // Protected
  BASE: '/:companyId',
  CANDIDATES: 'candidates',
  RECRUITMENT: 'recruitment',
  RECRUITMENT_APPLICATIONS: 'recruitment/:jobPostId/applications',
  NEW_RECRUITMENT: 'recruitment/new',
  NEW_RECRUITMENT_JOB_POST: 'recruitment/new/job-post',
  NEW_RECRUITMENT_SOFT_SKILLS: 'recruitment/new/soft-skills',
  NEW_RECRUITMENT_QUESTIONS: 'recruitment/new/questions',
  NEW_RECRUITMENT_HARD_SKILLS: 'recruitment/new/hard-skills',
  NEW_RECRUITMENT_CUSTOMIZE: 'recruitment/new/customize',
  NEW_RECRUITMENT_SUITABILITY: 'recruitment/new/suitability',
  NEW_RECRUITMENT_CONFIG: 'recruitment/new/config',
  NEW_RECRUITMENT_COMPLETED: 'recruitment/new/completed',
  EDIT_RECRUITMENT: 'recruitment/:jobPostId/edit',
  SKILL_LIBRARY: 'skill-library',
  NEW_CUSTOM_QUESTIONNAIRE: 'skill-library/new-questionnaire',
  EDIT_CUSTOM_QUESTIONNAIRE: 'skill-library/edit-questionnaire',
  MY_ACCOUNT: 'my-account',
  COMPANY: 'company',
  TEAM: 'team',
  SETTINGS: 'settings',
  SETTINGS_INTEGRATIONS: 'settings/integrations',
  // Public
  PUBLIC: '/public',
  LOGIN: 'login',
  RECOVERY: 'recovery',
  RESET: 'reset/:resetCode',
  // Others
  UNAUTHORIZED: '/unauthorized',
} as const;

const RoutePermission: Record<string, string | null> = {
  // Protected routes
  [Routes.CANDIDATES]: null,
  [Routes.RECRUITMENT]: 'VIEW_SCREENINGS',
  [Routes.RECRUITMENT_APPLICATIONS]: null,
  [Routes.NEW_RECRUITMENT]: 'ADD_SCREENINGS',
  [Routes.EDIT_RECRUITMENT]: 'EDIT_SCREENINGS',
  [Routes.MY_ACCOUNT]: 'EDIT_PROFILE',
  [Routes.COMPANY]: 'VIEW_COMPANY',
  [Routes.TEAM]: 'VIEW_MEMBERS',
  [Routes.UNAUTHORIZED]: null,
  // Public routes
  [Routes.LOGIN]: null,
  [Routes.RECOVERY]: null,
};

export const canNavigate = (path: string, availablePermissions: string[]): boolean => {
  const requiredPermission = RoutePermission[path];
  if (!requiredPermission) {
    return true;
  } else {
    return availablePermissions.includes(requiredPermission);
  }
};
