import React from 'react';
import styled from 'styled-components';

interface Props {
  opened: boolean;
  onClick: () => void;
}

const PopupBackdrop: React.FunctionComponent<Props> = ({ opened, onClick }) => {
  return (
    <Container
      opened={opened}
      onClick={onClick}
    />
  );
};

export default PopupBackdrop;

const Container = styled.div<{ opened: boolean }>`
  position: fixed;
  z-index: ${(props) => (props.opened ? 98 : -1)};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
