import { createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_ENDPOINTS } from '../../constants/endpoints';
import { ActionsEnum } from '../../enums/actions.enum';
import { SoftSkill } from '../../models/ThunksInterfaces/retrieveAvailableSkills';
import { JobAreaModel } from '../../models/jobArea.model';
import { JobSubFamilyModel } from '../../models/jobSubFamily.model';
import { http } from '../../utils/http';

interface JobAreaResponseModel {
  id: number;
  language_id: number;
  name: string;
  slug: string;
  visible: 'always' | 'never' | 'not-selectable';
  created_at: string;
  updated_at: string;
}

export interface Suggestion {
  seniority_id: number;
  seniority_name: string;
  seniority_slug: string;
  suggested_soft_skills_parent_id: number[];
  suggested_questions_parent_id: number[];
}

export const RETRIEVE_AVAILABLE_JOB_AREAS = createAsyncThunk(
  ActionsEnum.RETRIEVE_AVAILABLE_JOB_AREAS,
  async (): Promise<JobAreaModel[]> => {
    let data: JobAreaModel[];
    try {
      const response = await http(BACKEND_ENDPOINTS.JOB_AREAS, {
        method: 'GET',
      });
      data = response.data.data.areas.map((v: JobAreaResponseModel) => ({
        id: v.id,
        language_id: v.language_id,
        text: v.name,
        value: v.id,
        visible: v.visible,
        created_at: v.created_at,
      }));
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);

export const RETRIEVE_AVAILABLE_JOB_SUB_FAMILIES = createAsyncThunk(
  ActionsEnum.RETRIEVE_AVAILABLE_JOB_SUB_FAMILIES,
  async ({ jobAreaId }: { jobAreaId: string }): Promise<JobSubFamilyModel[]> => {
    let sub_families: JobSubFamilyModel[] = [];
    try {
      const response = await http(`${BACKEND_ENDPOINTS.JOB_AREAS}/${jobAreaId}/sub-families`, {
        method: 'GET',
      });
      sub_families = response.data.data.sub_families;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return sub_families;
  }
);

export const RETRIEVE_AVAILABLE_JOB_SENIORITY = createAsyncThunk(
  ActionsEnum.RETRIEVE_AVAILABLE_JOB_SENIORITY,
  async ({ jobAreaId }: { jobAreaId: string }): Promise<Suggestion[]> => {
    let suggestions: Suggestion[] = [];
    try {
      const response = await http(`${BACKEND_ENDPOINTS.JOB_AREAS}/${jobAreaId}/seniority`, {
        method: 'GET',
      });
      suggestions = response.data.data.suggestions;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return suggestions;
  }
);

export const RETRIEVE_SUGGESTED_SKILLS = createAsyncThunk(
  ActionsEnum.RETRIEVE_SUGGESTED_SKILLS,
  async ({
    companyId,
    languageId,
    areaId,
    seniorityId,
  }: {
    companyId: string;
    languageId: number;
    areaId: string;
    seniorityId: string;
  }): Promise<SoftSkill[]> => {
    let data: SoftSkill[];
    try {
      const response = await http(`${BACKEND_ENDPOINTS.RETRIEVE_AVAILABLE_SKILLS}/suggested`, {
        method: 'GET',
        params: {
          companyId,
          languageId,
          areaId,
          seniorityId,
        },
      });
      data = response.data.data.suggestions;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);
