import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { BACKEND_ENDPOINTS } from '../../constants/endpoints';
import { ActionsEnum } from '../../enums/actions.enum';
import { Feedback } from '../../models/feedback.model';
import { http } from '../../utils/http';

export const RETRIEVE_TEAM_FEEDBACKS = createAsyncThunk(
  ActionsEnum.RETRIEVE_TEAM_FEEDBACKS,
  async (applicationId: number): Promise<Feedback[]> => {
    let data: Feedback[];
    try {
      const response = await http(`${BACKEND_ENDPOINTS.TEAM_FEEDBACKS}/${applicationId}`, {
        method: 'GET',
      } as AxiosRequestConfig);
      data = response.data.data.feedbacks;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);

export const INSERT_TEAM_FEEDBACK = createAsyncThunk(
  ActionsEnum.INSERT_TEAM_FEEDBACK,
  async ({
    applicationId,
    feedback,
  }: {
    applicationId: number;
    feedback: string;
  }): Promise<Feedback> => {
    let data: Feedback;
    try {
      const response = await http(`${BACKEND_ENDPOINTS.TEAM_FEEDBACKS}/${applicationId}`, {
        method: 'POST',
        data: {
          feedback,
        },
      } as AxiosRequestConfig);
      data = response.data.data.feedback;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);
