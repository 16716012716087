import { HTMLAttributes, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  size?: 'sm' | 'lg';
  appearance?: 'primary' | 'secondary' | 'text';
  animated?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  dropdownContainer?: boolean;
  type?: 'button' | 'submit' | 'reset';
  iconOnly?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
}

const BlackButton = ({
  size = 'lg',
  appearance = 'primary',
  animated = false,
  disabled = false,
  rounded = false,
  dropdownContainer,
  type,
  iconOnly = false,
  onClick,
  children,
}: Props) => {
  if (rounded) {
    return (
      <RoundContainer
        type={type ? type : 'button'}
        iconOnly={iconOnly}
        size={size}
        appearance={appearance}
        disabled={disabled}
        dropdownContainer={dropdownContainer}
        onClick={onClick ? onClick : () => null}
      >
        {children}
      </RoundContainer>
    );
  }

  return (
    <Container
      type={type ? type : 'button'}
      iconOnly={iconOnly}
      size={size}
      appearance={appearance}
      animated={animated}
      disabled={disabled}
      dropdownContainer={dropdownContainer}
      onClick={onClick ? onClick : () => null}
    >
      {children}
    </Container>
  );
};

export default BlackButton;

const Container = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${({ appearance }) => (appearance === 'primary' ? '#101010' : '#FFFFFF')};
  color: ${({ appearance }) => (appearance === 'primary' ? '#FFFFFF' : '#101010')};
  border-radius: ${({ theme }) => theme.radius.small};
  outline: none;
  border: ${({ appearance }) =>
    appearance === 'text'
      ? 'none'
      : appearance === 'secondary'
        ? '1px solid #ebebeb'
        : '1px solid #101010'};
  font-size: ${({ size }) => (size === 'sm' ? '14px' : '15px')};
  font-weight: 600;
  padding: ${({ size }) => (size === 'sm' ? '4px 12px' : '8px 16px')};
  margin-right: 10px;
  ${({ dropdownContainer }) => (dropdownContainer ? 'position: relative;' : null)};
  transition: all 0.2s linear;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:disabled {
    cursor: not-allowed;
    background: ${({ appearance }) => (appearance === 'text' ? null : '#f7f7f7')};
    color: #475467;
    border-color: ${({ appearance }) => (appearance === 'text' ? 'transparent' : '#f7f7f7')};
  }

  > svg {
    ${({ iconOnly }) => (iconOnly ? null : 'margin-right: 10px;')};
  }

  ${({ animated, appearance }) =>
    animated
      ? appearance === 'primary'
        ? `&:hover {
          background-color: #FFFFFF;
          color: #101010;
        }`
        : `&:hover {
          background-color: #101010;
          color: #FFFFFF;
        }`
      : null}
`;

const RoundContainer = styled(Container)`
  border-radius: ${({ theme }) => theme.radius.default};
`;
