import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ChangePasswordForm from '../../../Forms/ChangePasswordForm/ChangePasswordForm';

interface Props {
  loading: boolean;
  onSubmit: (password: string) => void;
}

const PasswordChangeRequired = ({ loading, onSubmit }: Props) => {
  const { t } = useTranslation('modals');
  return (
    <Container>
      <Title>{t('createPassword.title')}</Title>
      <ChangePasswordForm
        loading={loading}
        onSubmit={onSubmit}
      />
    </Container>
  );
};

export default PasswordChangeRequired;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  padding: ${({ theme }) => theme.spacer[8]};
`;
const Title = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.size[20]};
  font-weight: bold;
  color: ${({ theme }) => theme.lightUI.text.default};
  margin-bottom: ${({ theme }) => theme.spacer[8]};
`;
