import { createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_ENDPOINTS } from '../../constants/endpoints';
import { ActionsEnum } from '../../enums/actions.enum';
import { RecruitmentJobPostModel } from '../../models/Forms/jobPost.model';
import {
  HardSkill,
  RetrieveHardSkillsPayloadModel,
} from '../../models/ThunksInterfaces/retrieveAvailableSkills';
import { http } from '../../utils/http';
import { setSuccessMessage } from '../popup/actions';
import { CreatedState } from './reducer';

export const RETRIEVE_HARD_SKILLS = createAsyncThunk(
  ActionsEnum.RETRIEVE_HARD_SKILLS,
  async ({
    companyId,
    languageId,
    areaId,
    seniorityId,
    subFamilyId,
  }: RetrieveHardSkillsPayloadModel): Promise<HardSkill[]> => {
    let hard_skills: HardSkill[] = [];
    try {
      const response = await http(`${BACKEND_ENDPOINTS.RETRIEVE_HARD_SKILLS}/suggested`, {
        method: 'GET',
        params: {
          companyId,
          languageId,
          areaId,
          seniorityId,
          subFamilyId,
        },
      });
      hard_skills = response.data.data.hard_skills;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return hard_skills;
  }
);

export const CREATE_JOB_POST = createAsyncThunk(
  ActionsEnum.CREATE_JOB_POST,
  async (recruitment: RecruitmentJobPostModel, thunkAPI): Promise<CreatedState> => {
    let data: CreatedState;
    try {
      const formData: FormData = new FormData();
      formData.append('companyId', recruitment.companyId.toString());
      formData.append('name', recruitment.name);
      formData.append('lang', recruitment.lang.toString());
      formData.append('type', recruitment.type);
      formData.append('area_id', recruitment.area_id.toString());
      formData.append('sub_family_id', recruitment.sub_family_id.toString());
      formData.append('seniority_id', recruitment.seniority_id.toString());
      formData.append(
        'soft_skills',
        recruitment.soft_skills.length > 0 ? recruitment.soft_skills.join(',') : ' '
      );
      formData.append(
        'questions',
        recruitment.questions.length > 0 ? recruitment.questions.join(',') : ' '
      );
      formData.append(
        'questionnaires',
        recruitment.questionnaires.length > 0 ? recruitment.questionnaires.join(',') : ' '
      );
      formData.append('smsSender', recruitment.smsSender);
      formData.append('smsContent', recruitment.smsContent);
      formData.append('retake_attempts', `${recruitment.retake_attempts}`);
      formData.append(
        'guest_authentication_enabled',
        `${recruitment.guest_authentication_enabled}`
      );
      formData.append(
        'presentation_interview_enabled',
        recruitment.presentation_interview_enabled.toString()
      );
      formData.append('filter_questions_enabled', recruitment.filter_questions_enabled.toString());
      formData.append('filter_questions', JSON.stringify(recruitment.filter_questions));
      formData.append(
        'custom_questionnaires',
        recruitment.custom_questionnaires.length > 0
          ? recruitment.custom_questionnaires.join(',')
          : ' '
      );
      // Not mandatory params
      if (recruitment.smsEdited) {
        formData.append('smsEdited', `${recruitment.smsEdited}`);
      }
      if (recruitment.emailSubject) {
        formData.append('emailSubject', recruitment.emailSubject);
      }
      if (recruitment.emailContent) {
        formData.append('emailContent', recruitment.emailContent);
      }
      if (recruitment.introductionContent) {
        formData.append('introductionContent', recruitment.introductionContent);
      }
      if (recruitment.expiration) {
        formData.append('expiration', recruitment.expiration);
      }
      if (recruitment.welcome_video) {
        formData.append('welcome_video', recruitment.welcome_video);
      }
      if (recruitment.location) {
        formData.append('location', recruitment.location);
      }
      if (recruitment.business_hours) {
        formData.append('business_hours', recruitment.business_hours);
      }
      if (recruitment.cv_required) {
        formData.append('cv_required', `${recruitment.cv_required}`);
      }
      if (recruitment.contract) {
        formData.append('contract', recruitment.contract);
      }
      if (recruitment.description) {
        formData.append('description', recruitment.description);
      } else {
        formData.append('description', '<p class="editor-paragraph"><br></p>'); // NOTE: Description is mandatory: send default Lexical empty structure in case of video interview
      }
      if (recruitment.notifications) {
        formData.append('notifications', `${recruitment.notifications}`);
      }
      if (recruitment.presentation_interview_question) {
        formData.append(
          'presentation_interview_question',
          recruitment.presentation_interview_question
        );
      }
      if (recruitment.filter_questions_feedback_title) {
        formData.append(
          'filter_questions_feedback_title',
          recruitment.filter_questions_feedback_title
        );
      }
      if (recruitment.filter_questions_feedback_description) {
        formData.append(
          'filter_questions_feedback_description',
          recruitment.filter_questions_feedback_description
        );
      }
      if (recruitment.ats_job_post) {
        formData.append('ats_job_post', recruitment.ats_job_post);
      }

      const response = await http(BACKEND_ENDPOINTS.JOB_POSTS, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      data = response.data.data.job;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(
      setSuccessMessage({ message: 'recruitment.createJobPost.confirm', job: data.name })
    );
    return data;
  }
);
