import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { LOGOUT } from '../store/auth/thunk';

// REFACTOR: Add better errors management
export const ApiErrorsMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    //This is caused by backend giving 401 expired access token. This is temporary.
    if (
      action.payload.status === 401 ||
      (action.payload.status === 'PARSING_ERROR' && action.payload.originalStatus === 401)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      api.dispatch(LOGOUT() as any);
    }
  }

  return next(action);
};
