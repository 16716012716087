import { createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_ENDPOINTS } from '../../constants/endpoints';
import { ActionsEnum } from '../../enums/actions.enum';
import { RetrieveApplications } from '../../features/applications/interfaces/retrieveApplications';
import { JobPostUpdateModel } from '../../models/Forms/jobPost.model';
import { http } from '../../utils/http';
import { setErrorMessage, setSuccessMessage } from '../popup/actions';

interface EditRecruitmentPayloadRequest {
  jobPostId: number;
  recruitment: Partial<JobPostUpdateModel>;
}

export const EDIT_RECRUITMENT = createAsyncThunk(
  ActionsEnum.EDIT_RECRUITMENT,
  async (
    { jobPostId, recruitment }: EditRecruitmentPayloadRequest,
    thunkAPI
  ): Promise<RetrieveApplications.Job> => {
    let data: RetrieveApplications.Job;
    try {
      const formData: FormData = new FormData();

      if (recruitment.name) {
        formData.append('name', recruitment.name);
      }
      if (recruitment.area) {
        formData.append('area_id', recruitment.area.toString());
      }
      if (recruitment.subFamily) {
        formData.append('sub_family_id', recruitment.subFamily.toString());
      }
      if (recruitment.seniority) {
        formData.append('seniority_id', recruitment.seniority.toString());
      }
      if (recruitment.softSkills) {
        formData.append('soft_skills', recruitment.softSkills.join(','));
      }
      if (recruitment.questions) {
        formData.append('questions', recruitment.questions.join(','));
      }
      if (recruitment.emailSubject) {
        formData.append('emailSubject', recruitment.emailSubject);
      }
      if (recruitment.emailText) {
        formData.append('emailContent', recruitment.emailText);
      }
      if (recruitment.smsSender) {
        formData.append('smsSender', recruitment.smsSender);
      }
      if (recruitment.smsContent) {
        formData.append('smsContent', recruitment.smsContent);
      }
      if (recruitment.smsSender || recruitment.smsContent) {
        formData.append('smsEdited', 'true');
      }
      if (recruitment.welcomeText) {
        formData.append('introductionContent', recruitment.welcomeText);
      }
      if (recruitment.expiration) {
        formData.append('expiration', recruitment.expiration);
      }
      // Note: We can't send a NULL value with a FormData, thus we need to send a string
      if (recruitment.welcomeVideo !== undefined) {
        if (recruitment.welcomeVideo) {
          formData.append('welcome_video', recruitment.welcomeVideo);
        } else {
          formData.append('welcome_video_deleted', 'true');
        }
      }
      if (recruitment.location) {
        formData.append('location', recruitment.location);
      }
      if (recruitment.businessHours) {
        formData.append('business_hours', recruitment.businessHours);
      }
      if (
        recruitment.cvRequired !== undefined &&
        recruitment.cvRequired !== null &&
        typeof recruitment.cvRequired === 'boolean'
      ) {
        formData.append('cv_required', `${recruitment.cvRequired}`);
      }
      if (recruitment.contract) {
        formData.append('contract', recruitment.contract);
      }
      if (recruitment.description) {
        formData.append('description', recruitment.description);
      }
      if (
        recruitment.notifications !== undefined &&
        recruitment.notifications !== null &&
        typeof recruitment.notifications === 'boolean'
      ) {
        formData.append('notifications', `${recruitment.notifications}`);
      }
      if (recruitment.retakeAttempts !== undefined && recruitment.retakeAttempts !== null) {
        formData.append('retake_attempts', String(recruitment.retakeAttempts));
      }
      if (
        recruitment.guestAuthentication !== undefined &&
        recruitment.guestAuthentication !== null
      ) {
        formData.append('guest_authentication_enabled', String(recruitment.guestAuthentication));
      }
      if (recruitment.presentation_interview_enabled) {
        formData.append(
          'presentation_interview_enabled',
          recruitment.presentation_interview_enabled
        );
      }
      if (recruitment.presentation_interview_question) {
        formData.append(
          'presentation_interview_question',
          recruitment.presentation_interview_question
        );
      }
      if (
        recruitment.filter_questions_enabled !== undefined &&
        recruitment.filter_questions_enabled !== null &&
        typeof recruitment.filter_questions_enabled === 'boolean'
      ) {
        formData.append('filter_questions_enabled', String(recruitment.filter_questions_enabled));
      }
      if (recruitment.filter_questions_feedback_title) {
        formData.append(
          'filter_questions_feedback_title',
          recruitment.filter_questions_feedback_title
        );
      }
      if (recruitment.filter_questions_feedback_description) {
        formData.append(
          'filter_questions_feedback_description',
          recruitment.filter_questions_feedback_description
        );
      }
      if (recruitment.filter_questions) {
        formData.append('filter_questions', JSON.stringify(recruitment.filter_questions));
      }

      const response = await http(`${BACKEND_ENDPOINTS.JOB_POSTS}/${jobPostId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      data = { ...response.data.data };
    } catch (e: any) {
      thunkAPI.dispatch(setErrorMessage({ message: 'recruitment.editJobPost.reject' }));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(
      setSuccessMessage({ message: 'recruitment.editJobPost.confirm', job: data.name })
    );
    return data;
  }
);
