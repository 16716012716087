import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TeamMembersResponse } from '../../models/ThunksInterfaces/retrieveTeamMembers';
import { SeatsModel, TeamMemberModel } from '../../models/teamMember.model';
import { openDeleteTeamMemberModal } from '../ui/actions';
import { ADD_TEAM_MEMBER, DELETE_TEAM_MEMBER, RETRIEVE_TEAM_MEMBERS } from './thunk';

export interface TeamState {
  loading: boolean;
  members: TeamMemberModel[];
  seats: SeatsModel;
  selectedMember: TeamMemberModel;
  invited: boolean;
}

const TeamInitialState: TeamState = {
  loading: false,
  members: [],
  seats: {} as SeatsModel,
  selectedMember: {} as TeamMemberModel,
  invited: false,
};

const TEAM_SLICE = 'Team';

const slice = createSlice({
  name: TEAM_SLICE,
  initialState: TeamInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(RETRIEVE_TEAM_MEMBERS.pending, (state, _) => ({
        ...state,
        loading: true,
        selectedMember: {} as TeamMemberModel,
        invited: false,
      }))
      .addCase(
        RETRIEVE_TEAM_MEMBERS.fulfilled,
        (state, action: PayloadAction<TeamMembersResponse>) => ({
          ...state,
          loading: false,
          members: [...action.payload.users],
          seats: action.payload.seats,
        })
      )
      .addCase(RETRIEVE_TEAM_MEMBERS.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(ADD_TEAM_MEMBER.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(ADD_TEAM_MEMBER.fulfilled, (state, action: PayloadAction<TeamMembersResponse>) => ({
        ...state,
        loading: false,
        members: [...action.payload.users],
        seats: action.payload.seats,
        invited: true,
      }))
      .addCase(ADD_TEAM_MEMBER.rejected, (state, _) => ({
        ...state,
        loading: false,
        invited: false,
      }))
      .addCase(DELETE_TEAM_MEMBER.pending, (state, _) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        DELETE_TEAM_MEMBER.fulfilled,
        (state, action: PayloadAction<TeamMembersResponse>) => ({
          ...state,
          loading: false,
          members: [...action.payload.users],
          seats: action.payload.seats,
          selectedMember: {} as TeamMemberModel,
        })
      )
      .addCase(DELETE_TEAM_MEMBER.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(openDeleteTeamMemberModal, (state, action: PayloadAction<TeamMemberModel>) => ({
        ...state,
        selectedMember: action.payload,
      })),
});

export default slice.reducer;
