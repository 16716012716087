import { createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_ENDPOINTS } from '../../../constants/endpoints';
import { ActionsEnum } from '../../../enums/actions.enum';
import { setErrorMessage, setSuccessMessage } from '../../../store/popup/actions';
import { http } from '../../../utils/http';
import { CustomQuestionnaire, SubmitCustomQuestionnairePayload } from '../interfaces';

export const GET_COMPANY_SUGGESTED_SKILLS = createAsyncThunk<
  { id: number; text: string }[],
  number
>(ActionsEnum.GET_COMPANY_SUGGESTED_SKILLS, async (companyId: number) => {
  let skills: { id: number; text: string }[] = [];
  try {
    const response = await http(`${BACKEND_ENDPOINTS.RETRIEVE_CUSTOM_QUESTIONNAIRES}/skills`, {
      method: 'GET',
      params: {
        companyId,
      },
    });
    skills = response.data.data.questionnaires_skills;
  } catch (e) {
    console.error(e);
  }
  return skills;
});

export const GET_COMPANY_SUGGESTED_TOPICS = createAsyncThunk<
  { id: number; text: string }[],
  number
>(ActionsEnum.GET_COMPANY_SUGGESTED_TOPICS, async (companyId: number) => {
  let topics: { id: number; text: string }[] = [];
  try {
    const response = await http(`${BACKEND_ENDPOINTS.RETRIEVE_CUSTOM_QUESTIONNAIRES}/topics`, {
      method: 'GET',
      params: {
        companyId,
      },
    });
    topics = response.data.data.questionnaires_topics;
  } catch (e) {
    console.error(e);
  }
  return topics;
});

export const RETRIEVE_A_CUSTOM_QUESTIONNAIRE = createAsyncThunk<CustomQuestionnaire, number>(
  ActionsEnum.RETRIEVE_A_CUSTOM_QUESTIONNAIRE,
  async (questionnaireId) => {
    let customQuestionnaire: CustomQuestionnaire;
    try {
      const response = await http(
        `${BACKEND_ENDPOINTS.RETRIEVE_CUSTOM_QUESTIONNAIRES}/${questionnaireId}`,
        { method: 'GET' }
      );
      customQuestionnaire = response.data.data.questionnaire;
    } catch (e) {
      console.error(e);
      throw new Error('Failed to retrieve questionnaire');
    }
    return customQuestionnaire;
  }
);

export const CREATE_CUSTOM_QUESTIONNAIRE = createAsyncThunk<
  CustomQuestionnaire,
  SubmitCustomQuestionnairePayload
>(ActionsEnum.CREATE_CUSTOM_QUESTIONNAIRE, async ({ companyId, questionnaire }, { dispatch }) => {
  let customQuestionnaire: CustomQuestionnaire;
  try {
    const response = await http(BACKEND_ENDPOINTS.RETRIEVE_CUSTOM_QUESTIONNAIRES, {
      method: 'POST',
      data: {
        companyId,
        questionnaire,
      },
    });
    customQuestionnaire = response.data.data.questionnaire;
    dispatch(setSuccessMessage({ message: 'customQuestionnaires.wizard.creation.success' }));
  } catch (e) {
    console.error(e);
    dispatch(setErrorMessage({ message: 'customQuestionnaires.wizard.creation.failed' }));
    throw new Error('Failed to retrieve questionnaire');
  }
  return customQuestionnaire;
});
export const EDIT_CUSTOM_QUESTIONNAIRE = createAsyncThunk<void, SubmitCustomQuestionnairePayload>(
  ActionsEnum.EDIT_CUSTOM_QUESTIONNAIRE,
  async ({ companyId, questionnaire }, { dispatch }) => {
    try {
      await http(`${BACKEND_ENDPOINTS.RETRIEVE_CUSTOM_QUESTIONNAIRES}/${questionnaire.id}`, {
        method: 'PATCH',
        data: {
          companyId,
          questionnaire,
        },
      });
      dispatch(setSuccessMessage({ message: 'customQuestionnaires.wizard.editing.success' }));
    } catch (e) {
      console.error(e);
      dispatch(setErrorMessage({ message: 'customQuestionnaires.wizard.editing.failed' }));
      throw new Error('Failed to retrieve questionnaire');
    }
    return;
  }
);
