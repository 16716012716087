import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Feedback } from '../../models/feedback.model';
import { INSERT_TEAM_FEEDBACK, RETRIEVE_TEAM_FEEDBACKS } from './thunk';

export interface TeamFeedbacksState {
  loading: boolean;
  feedbacks: Feedback[];
}

const TeamFeedbacksInitialState: TeamFeedbacksState = {
  loading: false,
  feedbacks: [],
};

const TEAM_FEEDBACKS_SLICE = 'Team Feedbacks';

const slice = createSlice({
  name: TEAM_FEEDBACKS_SLICE,
  initialState: TeamFeedbacksInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(RETRIEVE_TEAM_FEEDBACKS.pending, (state, _) => ({
        ...state,
        loading: true,
        feedbacks: [],
      }))
      .addCase(RETRIEVE_TEAM_FEEDBACKS.fulfilled, (state, action: PayloadAction<Feedback[]>) => ({
        ...state,
        loading: false,
        feedbacks: action.payload,
      }))
      .addCase(RETRIEVE_TEAM_FEEDBACKS.rejected, (state, _) => ({
        ...state,
        loading: false,
      }))
      .addCase(INSERT_TEAM_FEEDBACK.fulfilled, (state, action: PayloadAction<Feedback>) => ({
        ...state,
        feedbacks: [{ ...action.payload }, ...state.feedbacks],
      })),
});

export default slice.reducer;
